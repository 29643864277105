export const base = {
  "src.components.routes.pages.Root.title": "NutritionPro",
  "src.components.routes.pages.BackBoxes.title": "NutritionPro",

  "error.date.required": "Date is required",
  "error.paymentMethod.required": "Payment method is required.",
  "src.components.atoms.PricePerDay.title": "Price per menu",
  "src.components.atoms.PricePerDay.description": "(excluding delivery)",
  "src.components.atoms.PricePerDay.from": "from",
  "src.components.atoms.PricePerDay.symbol": "{price} CZK",
  "src.components.molecules.PriceCard,symbol": "{price} CZK",
  "src.components.routes.pages.DietBrno.title": "Brno",
  "src.components.molecules.PriceCard.title": "Order price",
  "src.components.molecules.PriceCard.delete": "Remove",
  "src.components.molecules.PriceCard.btn": "Continue",
  "error.companyName.required": "Enter company name",
  "error.companyAddress.required": "Enter billing address",
  "error.regNumber.required": "Enter company registration number",
  "error.vatNumber.required": "Enter company VAT number",
  "error.polygon.required": "City for your address",
  "error.pickUpPoint.required": "Your pickup point",
  "error.addressCity.required": "Enter your address",
  "error.zip.required": "Enter company ZIP code",
  "src.components.molecules.PriceCard.promo": "Promo code „{promo}“",
  "src.components.routes.pages.DietHradec.title": "Hradec Králové",
  "src.components.routes.pages.DietJablonec.title": "Jablonec nad Nisou",
  "src.components.routes.pages.DietKladno.title": "Kladno",
  "src.components.routes.pages.DietSredniCechi.title": "in Central Bohemia",

  "src.components.routes.pages.DietLiberec.title": " Liberec",
  "src.components.routes.pages.DietMlda.title": "Mladá Boleslav",
  "src.components.organisms.Footer.partner.btn": "Become a partner",
  "src.components.organisms.ThankYouModal.succsesTitle":
    "Your request has been successfully received",
  "src.components.organisms.ThankYouModal.failTitle":
    "Something went wrong. Please try again",
  "src.components.organisms.GiftVoucherModal.description":
    "A gift voucher from NutritionPro is a gift everyone will appreciate",
  "src.components.organisms.GiftVoucherModal.titleFirst": "Make your",
  "src.components.organisms.GiftVoucherModal.titleSecond": "loved ones happy",
  "src.components.organisms.ThankYouModal.btn": "Back to homepage",

  "src.components.molecules.DeliveryInfo.title": "Delivery is always",
  "src.components.molecules.DeliveryInfo.link": "free",
  "src.components.molecules.OrderRightCard.jidel": "{jidel} course menu",
  "src.components.molecules.PromoCodeLink.promoCode": "Enter promo code",
  "src.components.molecules.OrderRightCard.btn": "Order online",
  "src.components.molecules.OrderRightCard.pocrovat": "Continue",
  "src.components.routes.pages.DietParadubice.title": "Pardubice",
  "src.components.routes.pages.DietPlzen.title": "Plzeň",
  "src.components.routes.pages.DietPraha.title": "Prague and surroundings",
  "src.components.routes.pages.Gdpr.title":
    "Basic information on personal data processing and contacts",
  "src.components.organisms.ContactForm.Checkbox.personalData.title":
    "I agree with the processing",
  "src.components.organisms.ContactForm.Checkbox.personalData.link":
    " of personal data",
  "src.components.molecules.CallMeCard.title": "Need help choosing?",
  "src.components.molecules.CallMeCard.description": "Call us.",
  "src.components.organisms.ProgramBlock.titleFirst": "Choose ",
  "src.components.organisms.ProgramBlock.titleSecond":
    " one of our diet box programs",
  "src.components.organisms.ProgramBlock.description":
    "Enjoy a wide range of flavors without limits. With all programs, you now have the option to swap any course.",
  "src.components.organisms.ContactForm.Checkbox.terms.title":
    "Consent to the processing of personal data for marketing purposes",
  "src.components.atoms.PricePerDay.dropava": "Menu price",
  "src.components.atoms.PricePerDay.dropavaDescription": "(excluding delivery)",

  "src.components.molecules.OnlineOrderFirstStepSidebar.promoCode":
    "Do you have a promo code?",
  "src.components.molecules.OnlineOrderFirstStepSidebar.order":
    "You can enter it at the end of the order.",
  "src.components.routes.pages.NotFound.title": "NotFound",
  "src.components.routes.pages.OnlineOrder.title": "OnlineOrder",
  "src.components.organisms.forms.redBtn": "I'm interested",
  "src.components.organisms.forms.GiftVoucherForm.btn": "Send",
  "src.components.organisms.forms.GiftVoucherForm.title":
    "Leave us your contact, we will get in touch with you and help you choose the right program",
  "src.components.routes.pages.Terms.title": "Všeobecné obchodní podmínky",
  "src.components.atoms.GiftVoucher.title": "Gift voucher",
  "src.components.atoms.WorkingHours.days": "Mon - Fri",
  "src.components.molecules.Menu.count": "Open positions",
  "src.components.atoms.Copyright.text":
    "Copyright ©{currentYear} NutritionPro s.r.o",
  "src.components.organisms.ProgramBlock.meat.title": "NutritionPro Classic",
  "src.components.organisms.ProgramBlock.meat.description":
    "Our classic represents the perfect blend of delicious flavors and optimal nutrition.",
  "src.components.organisms.ProgramBlock.light.title": "NutritionPro Light",
  "src.components.organisms.ProgramBlock.vege.description":
    "Are you a vegetarian or just want to take a break from meat? Then this menu is for you. It does not contain meat, including fish.",
  "src.components.organisms.ProgramBlock.vege.title": "NutritionPro Vege",
  "src.components.organisms.ProgramBlock.light.description":
    "A lighter menu without snacks at a great price, whether snacks don't suit you or you prefer to add them yourself.",
  "src.components.organisms.ProgramBlock.fish.title": "Menu without fish",
  "src.components.organisms.ProgramBlock.fish.description":
    "Fish is not for everyone, so this menu does not include fish or any fish products.",
  "src.components.organisms.ProgramBlock.combo.title": "NutritionPro combo",
  "src.components.organisms.ProgramBlock.combo.description":
    "This is a two-course menu (lunch and dinner) that you can choose according to your preferences.",
  "src.components.molecules.SocialMedia": "Follow us on social media:",
  "src.components.organisms.Footer.location.title": "Address",
  "src.components.organisms.Footer.phone.title": "Phone",
  "src.components.organisms.Footer.email.title": "Email",
  "src.components.organisms.Footer.diet": "Top-rated box diet.",
  "src.components.organisms.Footer.links": "Links",
  "src.components.organisms.Footer.program": "Programs",
  "src.components.molecules.ProgramCard.jidel": "{jidel} meals",
  "src.components.molecules.ProgramCard.jidla": "{jidel} dishes",
  "src.components.molecules.ProgramCard.link": "More information",
  "src.components.molecules.ProgramCard.price": "from {price} CZK",
  "src.components.atoms.OnlineProgramCard.price": "from {price} CZK",
  "src.components.atoms.OnlineProgramCard.day": "day",
  "src.components.molecules.ProgramCard.day": "day",
  "src.components.molecules.ProgramCard.orderBtn": "Order",
  "src.components.molecules.ProgramCard.modalbtn": "Consult",
  "src.components.organisms.Footer.menu": "Menu",
  "src.components.organisms.Footer.price": "Price",
  "src.components.organisms.Footer.career": "Career",
  "src.components.organisms.Footer.documents": "Documents",
  "src.components.organisms.Footer.personalData": "Handling of personal data",
  "src.components.organisms.Footer.terms": "Terms and conditions",
  "src.components.organisms.Footer.cookies": "Cookie policy",
  "src.components.organisms.Footer.settings": "Open cookie settings",
  "src.components.organisms.Footer.media": "For media, partners and companies",
  "src.components.organisms.Footer.partner": "Email: {email}",
  "src.components.organisms.DesktopHeader.price": "GET A QUOTE",
  "src.components.organisms.DesktopHeader.career": "Career",
  "src.components.organisms.MobileHeader.career": "Career",
  "src.components.organisms.DesktopHeader.blog": "Blog",
  "src.components.molecules.DeliveryItem.razvoz": "Delivery",
  "src.components.molecules.DeliveryItem.praha": "Diet box Prague",
  "src.components.molecules.DeliveryItem.brno": "Diet box Brno",
  "src.components.molecules.DeliveryItem.plzen": "Diet box Plzeň",
  "src.components.molecules.DeliveryItem.mlda": "Diet box Mladá Boleslav",
  "src.components.molecules.DeliveryItem.liberec": "Diet box Liberec",
  "src.components.molecules.DeliveryItem.hradec": "Diet box Hradec Králové",
  "src.components.molecules.DeliveryItem.paradubice": "Diet box Pardubice",
  "src.components.molecules.DeliveryItem.sredniCech":
    "Diet box Central Bohemia",
  "src.components.molecules.DeliveryItem.jablonec": "Diet box Jablonec",
  "src.components.organisms.MobileHeader.program": "Diet programs",
  "src.components.organisms.MobileHeader.customerPortal": "Client zone",
  "src.components.organisms.MobileHeader.menu": "Menu",
  "src.components.organisms.MobileHeader.price": "Price",
  "src.components.organisms.MobileHeader.blog": "Blog",
  "src.components.organisms.MultiOrder.titleFirst": "Joint order",
  "src.components.organisms.MultiOrder.titleSecond":
    "with a discount of up to 20 %",
  "src.components.organisms.ComplateMenuView.day": "DAY",
  "src.components.organisms.ComplateMenu.price": "{count} CZK",
  "src.components.organisms.ComplateMenu.priceTitle": "Great price",
  "src.components.organisms.ComplateMenu.titleFirst": " 2 days",
  "src.components.organisms.ComplateMenu.titleSecond": "Try the meal boxes for",
  "src.components.molecules.PhoneInput.label": "Your phone",
  "src.components.organisms.SpecialistBlock.arrowText":
    "{specialist} consultation with a nutrition expert is {price} for every program",
  "src.components.organisms.SpecialistBlock.arrowText.sepcialist":
    "Consultation",
  "src.components.organisms.SpecialistBlock.arrowText.price":
    "included in the price",
  "src.components.routes.pages.DietSredniCechi.subTitle":
    "The most accurate and flexible delivery",

  "src.components.organisms.SpecialistBlock.textSecond":
    "Do you have diet boxes but don't know what to eat on the weekend? Are you unsure about the best drinking regimen to pair with diet boxes? Do you want to ensure you're getting the right energy intake? Do you have other nutritional questions or are just starting? If you're unsure about anything, I'm here to help. You will be clear on everything.",
  "src.components.organisms.SpecialistBlock.sepicalist.text":
    "Expert nutritionist",
  "src.components.organisms.SpecialistBlock.sepicalist.title":
    "Jitka Dokoupilova",
  "src.components.organisms.forms.BannerForm.name": "First and Last Name",
  "src.components.organisms.forms.CallMeForm.name": "First and Last Name",
  "src.components.organisms.ComplateMenu.description":
    "Leave us your contact, and we will call you within two hours during business hours to arrange everything in 10 minutes.",
  "src.components.organisms.MultiOrder.discount":
    "First order for {count} people with a discount",
  "src.components.organisms.MultiOrder.order":
    "Order diet boxes with your partner, family, or colleagues and get a better price.",
  "src.components.organisms.HowWorks.titleFirst": "How",
  "src.components.organisms.SpecialistBlock.textFirst":
    "I am a {specialist} at NutritionPro and I live and breathe nutrition! I studied food analysis at VŠCHT and nutritional therapy at the First Faculty of Medicine. I have been advising clients for more than {years}, and during that time, I have helped over {count} clients.",
  "src.components.organisms.SpecialistBlock.specialist": "nutrition consultant",
  "src.components.organisms.SpecialistBlock.years": "15 years",
  "src.components.organisms.SpecialistBlock.count": "1500",

  "src.components.organisms.HowWorks.titleSecond": "does our diet box work?",
  "src.components.organisms.HowWorks.daily":
    "We set your daily intake according to your goals",

  "src.components.organisms.SpecialistBlock.titleFirst":
    "Always under the supervision",
  "src.components.organisms.SpecialistBlock.titleSecond":
    "of a nutrition expert",
  "src.components.organisms.HowWorks.algorithm":
    "A special algorithm creates a tailor-made menu",

  "src.components.organisms.HowWorks.cooking": "We cook the meals",
  "src.components.organisms.HowWorks.delivery":
    "We deliver it to your home or work",
  "src.components.organisms.HowWorks.goal":
    "We then ensure you reach your goal",
  "src.components.organisms.Delivery.titleFirst":
    "The most precise and flexible delivery already",
  "src.components.organisms.Delivery.titleSecond":
    " in 9 cities in the Czech Republic",
  "src.components.organisms.Delivery.titleSecondMob": " in 9 cities",
  "src.components.organisms.Delivery.titleSecondMobLast":
    "of the Czech Republic",
  "src.components.organisms.Delivery.description":
    "Check if we deliver to your area, or if one of our 16 pick-up points suits you.",
  "src.components.organisms.Delivery.praha": "Prague",
  "src.components.organisms.Delivery.brno": "Brno",
  "src.components.organisms.Delivery.plzen": "Plzeň",
  "src.components.organisms.Delivery.liberec": "Liberec",
  "src.components.organisms.Delivery.hradec": "Hradec Králové",
  "src.components.organisms.Delivery.paradubice": "Pardubice",
  "src.components.organisms.Delivery.jablonec": "Jablonec",
  "src.components.organisms.Delivery.kladno": "Central Bohemia",
  "src.components.organisms.Delivery.mlda": "Mladá Boleslav",
  "src.components.organisms.Delivery.praha.time":
    "Delivery in the evening between 17:00 - 22:00",
  "src.components.organisms.Delivery.brno.time":
    "Delivery in the evening between 18:00 - 23:00",
  "src.components.organisms.Delivery.plzen.time":
    "Delivery in the evening between 17:00 - 22:00",
  "src.components.organisms.Delivery.liberec.time":
    "Delivery in the evening between 17:00 - 22:00",
  "src.components.organisms.Delivery.hradec.time":
    "Delivery in the evening between 16:30 - 22:00.",
  "src.components.organisms.Delivery.paradubice.time":
    "Delivery in the evening between 16:30 - 22:00.",
  "src.components.organisms.Delivery.kladno.time":
    "Delivery in the evening between 17:00 - 22:00",
  "src.components.organisms.Delivery.jablonec.time":
    "Delivery in the evening between 18:00 - 22:00",
  "src.components.organisms.Delivery.mlda.time":
    "Delivery in the evening between 16:00 - 22:00",
  "src.components.organisms.Delivery.praha.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.brno.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.plzen.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.liberec.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.hradec.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.paradubice.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.kladno.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.jablonec.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.mlda.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.praha.point": "10 pick-up points",
  "src.components.molecules.DeliveryCard.delivery": "Free delvery",
  "src.components.organisms.HowCook.titleFirst": "Why ",
  "src.components.organisms.HowCook.titleSecond": "NutritionPro?",
  "src.components.organisms.HowCook.titleSecond.praha":
    " krabičková dieta Praha?",
  "src.components.organisms.HowCook.description":
    "Take a look at the unique process of preparing our great meals",
  "src.components.organisms.HowCook.area.title": "Modern production area",
  "src.components.organisms.HowCook.area.descritpion":
    "We use the most modern technology for the most gentle processing of ingredients.",
  "src.components.organisms.HowCook.suppliers.title": "The best suppliers",
  "src.components.organisms.HowCook.suppliers.description":
    "All our suppliers are long-term verified.",
  "src.components.organisms.HowCook.ingredients.title":
    "Only the highest quality ingredients",
  "src.components.organisms.Vege.titleFirst": "Vegetarian",
  "src.components.organisms.Vege.titleSecond": "menu",
  "src.components.organisms.Vege.list.title":
    "You can already enjoy our meat-free menu.",

  "src.components.organisms.Vege.list.description1":
    "Nutritionally balanced menu",

  "src.components.organisms.Vege.list.description2":
    "Sufficient protein intake",
  "src.components.organisms.Vege.list.description3": "Tasty and varied menu",
  "src.components.organisms.Vege.list.description4": "Premium ingredients",
  "src.components.organisms.Vege.form.title":
    "Our veggie boxes are waiting for you.",
  "src.components.organisms.Vege.form.description":
    "Enter your phone number and we'll get back to you.",
  "src.components.organisms.Vege.description":
    "Try something different and taste our meat-free dishes!",

  "src.components.organisms.HowCook.ingredients.description":
    "We check each delivery to ensure that only the highest quality ingredients are processed.",
  "src.components.organisms.HowCook.chefs.title": "Experienced chefs",
  "src.components.organisms.HowCook.chefs.descripton":
    "Our chefs cook according to the latest trends and procedures, daily preparing up to 5,000 dishes for you.",
  "src.components.molecules.GoogleMark.text":
    "{count}+ positive customer reviews",

  "src.components.routes.pages.DietPraha.deliveryTitle":
    "{greenTitle} diet box in Prague",
  "src.components.routes.pages.DietPraha.greenTitile": "Most flexible",
  "src.components.routes.pages.DietBrno.deliveryTitle":
    "{greenTitle} diet box in Brno",
  "src.components.routes.pages.DietBrno.greenTitile": "Most flexible",
  "src.components.routes.pages.DietPlzen.deliveryTitle":
    "{greenTitle} diet box in Plzeň",
  "src.components.routes.pages.DietPlzen.greenTitile": "Most flexible",
  "src.components.routes.pages.DietLiberec.deliveryTitle":
    "{greenTitle} diet box in Liberec",
  "src.components.routes.pages.DietLiberec.greenTitile": "Most flexible",
  "src.components.routes.pages.DietHradec.deliveryTitle":
    "{greenTitle} diet box in Hradec Králové",
  "src.components.routes.pages.DietHradec.greenTitile": "Most flexible",
  "src.components.routes.pages.DietHradecKralove.deliveryTitle":
    "{greenTitle} diet box in Hradec Králové",
  "src.components.routes.pages.DietHradecKralove.greenTitile": "Most flexible",
  "src.components.routes.pages.DietParadubice.deliveryTitle":
    "{greenTitle} diet box in Pardubice",
  "src.components.routes.pages.DietParadubice.greenTitile": "Most flexible",
  "src.components.routes.pages.DietSredniCechi.deliveryTitle":
    "{greenTitle} diet box in Central Bohemia",
  "src.components.routes.pages.DietSredniCechi.greenTitile": "Most flexible",
  "src.components.routes.pages.DietJablonec.deliveryTitle":
    "{greenTitle} diet box in Jablonec nad Nisou",
  "src.components.routes.pages.DietJablonec.greenTitile": "Most flexible",
  "src.components.routes.pages.DietJablonecNisou.deliveryTitle":
    "{greenTitle} diet box in Jablonec nad Nisou",
  "src.components.routes.pages.DietJablonecNisou.greenTitile": "Most flexible",
  "src.components.routes.pages.DietMldaBoleslav.deliveryTitle":
    "{greenTitle} diet box in Mladá Boleslav",
  "src.components.routes.pages.DietMldaBoleslav.greenTitile": "Most flexible",
  "src.components.routes.pages.DietMlda.deliveryTitle":
    "{greenTitle} diet box in Mladá Boleslav",
  "src.components.routes.pages.DietMlda.greenTitile": "Most flexible",
  "src.components.routes.pages.DietKladno.deliveryTitle":
    "{greenTitle} diet box in Kladno",
  "src.components.routes.pages.DietKladno.greenTitile": "Most flexible",
  "src.components.molecules.Partners.text": "Official partners",
  "src.components.organisms.RateBlock.titleFirst":
    "{greenTitle} rated diet box",
  "src.components.organisms.RateBlock.greenTitle": "Top-rated ",
  "src.components.molecules.BenefitCard.textFirst": "Choose from up to",
  "src.components.organisms.ReviewBlock.titleFirst": "Real customers ",
  "src.components.organisms.ReviewBlock.titleSecond": "say more than we do.",
  "src.components.atoms.Review.seeMore": "See all",
  "src.components.organisms.ReviewBlock.description":
    "We take an individual approach to each customer to ensure the best possible service.",
  "src.components.molecules.BenefitCard.benefit": "4 dishes ",
  "src.components.molecules.BenefitCard.textLast": "for each course.",
  "src.components.organisms.RateBlock.redBtn": "I'm interested",
  "src.components.organisms.HowCook.redBtn": "Order online",
  "src.components.organisms.CheckDelivery.title": "Check if we deliver to you.",

  "src.components.organisms.RateBlock.redBtnCity": "Select a program",

  "src.component.organisms.CheckDelivery.faild":
    "We're sorry! We are not in this location yet.",
  "src.component.organisms.CheckDelivery.success": "We deliver to your address",
  "src.component.organisms.CheckDelivery.placenholder":
    "Enter street, number, and city.",
  "src.components.molecules.AddressCheck.confirm": "Confirm",
  "src.components.organisms.Faq.titleFirst": "Frequently asked",
  "src.components.organisms.Faq.titleSecond": "questions",
  "src.components.organisms.Faq.menu.title": "Tailored menu",
  "src.components.organisms.Faq.materials.title": "Ingredients",
  "src.components.organisms.Faq.delivery.title": "Delivery and payment",
  "src.components.organisms.Faq.consumption.title": "How to consume",
  "src.components.organisms.Faq.menu.subTitle1":
    "What makes our diet box different?",
  "src.components.organisms.Faq.menu.content1":
    "Our {info} delivery is characterized by an individual approach, as we believe that every person is unique and needs a specific calorie intake to achieve their goals healthily. The menu for a 70kg woman who wants to lose weight will look entirely different from that for a 90kg man who is trying to gain muscle. Our meal delivery is designed precisely to meet your individual needs and goals, which is something that ordinary diet boxes often overlook.",
  "src.components.organisms.Faq.menu.subTitle2":
    "Who creates the menu for the diet box?",
  "src.components.organisms.Faq.menu.content2":
    "The menu for our {info} delivery is created by our special algorithm, which carefully considers your body's needs and your goals. Our nutrition expert, Jitka Dokoupilová, oversees the composition of individual meals to ensure you achieve the desired results",
  "src.components.organisms.Faq.menu.subTitle3":
    "What does the menu look like within the diet box?",

  "src.components.organisms.Faq.menu.content3":
    "Our {info} delivery is based on the principles of a balanced diet. The menu is varied and tasty, with more than 500 different dishes that are regularly updated. We constantly update our meal database to offer you new and exciting options, so our meals never get boring. Take a look at the sample menu we offer.",
  "src.components.organisms.Faq.materials.subTitle1":
    "What ingredients do you use for the diet box?",

  "src.components.organisms.Faq.materials.content1":
    "For our {info}, we use only high-quality and fresh ingredients from trusted Czech suppliers. The quality and freshness of the ingredients are the foundation of healthy eating for us.",
  "src.components.organisms.Faq.materials.subTitle2":
    "Are the meals within the diet box fresh?",
  "src.components.organisms.Faq.materials.content2":
    "Yes, all meals within our {info} are prepared from fresh ingredients that we purchase on the day of preparation. After cooking, the meals are shock-cooled and hermetically sealed, ensuring maximum freshness.",

  "src.components.organisms.Faq.materials.subTitle3":
    "When do you prepare meals for the diet box?",

  "src.components.organisms.Faq.materials.content3":
    "Meal preparation for our {info} takes place on the day of delivery. We prepare meals on Sunday for Monday and Tuesday, on Tuesday for Wednesday and Thursday, and on Thursday for Friday and Saturday.",
  "src.components.organisms.Faq.materials.subTitle4":
    "Do you also offer desserts within the diet box?",

  "src.components.organisms.Faq.materials.content4":
    "Yes, our {info} includes excellent desserts that are precisely calculated to fit into your personal menu.",
  "src.components.organisms.Faq.delivery.subTitle1":
    "How far in advance must I report skipping the delivery of the diet box?",
  "src.components.organisms.Faq.client.title": "Changes and Customer Support",
  "src.components.organisms.Faq.delivery.content1":
    "Any changes to the order within our {info}, including suspending delivery, must be reported no later than 11:00 AM three business days before the planned delivery. These rules apply because we purchase fresh ingredients for the exact number of customers for each cooking.",
  "src.components.organisms.Faq.delivery.subTitle2":
    "Can I cancel only one day within the diet box?",
  "src.components.organisms.Faq.delivery.content2":
    "If your {info} order covers two days, it is possible to cancel only the entire two-day order. Canceling just one day is not possible.",
  "src.components.organisms.Faq.delivery.subTitle3":
    "How is the price of the diet box determined?",

  "src.components.organisms.Faq.delivery.content3":
    "The price of our {info} delivery is determined individually according to your personal meal plan and daily calorie intake. The price may vary depending on your goals, whether it's losing weight, gaining muscle, or maintaining weight. You can calculate the price in our online calculator.",
  "src.components.organisms.Faq.delivery.subTitle4":
    "How do I pay for the diet box?",

  "src.components.organisms.Faq.delivery.content4":
    "After completing your {info} order, we will send you an invoice to your email. You can also choose to pay by card or meal vouchers.",
  "src.components.organisms.Faq.delivery.subTitle5":
    "How are we different from other diet boxes?",

  "src.components.organisms.Faq.delivery.content5":
    "We believe in an individual approach. Each of us is unique, and each of us needs a different calorie intake if we want to achieve our goal healthily. The menu for a 70kg woman who just wants to improve her diet and maintain her weight will look different from that for a 90kg man who wants to gain muscle or is trying to lose weight. The menu is therefore tailored to the body and goals, which ordinary diet boxes do not consider.",
  "src.components.organisms.Faq.delivery.subTitle6":
    "Which cities do you deliver the diet box to?",

  "src.components.organisms.Faq.delivery.content6":
    "We deliver our {info} to {prague}, {chech}, {kladno}, {plzen}, {liberec}, {mlda}, {jablonec}, {paradubic}, and {hradec}.",
  "src.components.organisms.Faq.delivery.subTitle7":
    "When do you deliver meals from the diet box?",

  "src.components.organisms.Faq.delivery.content7":
    "We deliver meals from our {info} every other day – on Tuesday, Thursday, and Sunday, always between 4:00 PM and 10:00 PM.",
  "src.components.organisms.Faq.delivery.subTitle8":
    "What time will my meal be delivered?",

  "src.components.organisms.Faq.delivery.content8":
    "The delivery time depends on the specific location and the time will be confirmed with the courier on the day of delivery",
  "src.components.organisms.Faq.delivery.subTitle9":
    "What if I need to change the delivery details for the diet box?",

  "src.components.organisms.Faq.delivery.content9":
    "We are flexible and understand that plans can change. You can change the time or place of delivery for our {info} on the same day until 12:00 PM.",
  "src.components.organisms.Faq.consumption.subTitle1":
    "Does the food from the diet box need to be heated?",

  "src.components.organisms.Faq.consumption.content1":
    "Some meals from our {info} are meant to be heated, while others can be eaten cold. For better taste, however, we recommend heating the meals. You can find out which meal needs to be heated by scanning the barcode on the box.",
  "src.components.organisms.Faq.consumption.subTitle2":
    "How to heat food from the diet box?",

  "src.components.organisms.Faq.consumption.content2":
    "If you're heating meals from our {info} in the microwave, we recommend poking holes in the foil. The heating time varies depending on the type of food and portion size, but we recommend using medium power (600–700 W) for even heating.",
  "src.components.organisms.Faq.consumption.subTitle3":
    "How to store meals from the diet box?",

  "src.components.organisms.Faq.consumption.content3":
    "We recommend storing meals from our {info} in the refrigerator, but you can also take them with you to work or on trips. The meals will last several hours outside the fridge without losing taste or quality.",
  "src.components.molecules.OnlineConfirmCard.price": "{price} CZK/day",
  "src.components.molecules.OnlineConfirmCard.jidel": "{jidel}-course menu",
  "src.components.atoms.ConfirmPrice.text": "Total price (incl. VAT)",
  "src.components.atoms.ConfirmPrice.price": "{price} CZK",
  "src.components.organisms.PaymentModal.successTitle":
    "Thank you, we have successfully received your payment.",

  "src.components.organisms.PaymentModal.failTitle":
    "Payment was not completed.",

  "src.components.organisms.PaymentModal.successText":
    "We are starting to process your order {number}. We have also sent this confirmation to your email.",
  "src.components.organisms.PaymentModal.failText":
    "We are sorry, but something went wrong and your payment was not completed.",
  "src.components.organisms.PaymentModal.succsesBtn": "Back to the main page",
  "src.components.organisms.PaymentModal.failBtn": "Try again",
  "src.components.organisms.PaymentModal.back": "Back to payment selection",
  "src.components.organisms.CookieModal.title":
    "To ensure NutritionPro works well for you",
  "error.name.min": "Name číslo musí mít alespoň 3 znaků",
  "error.phone.min": "Phone number must have at least 12 characters",
  "src.components.organisms.CookieModal.description":
    "To make your browsing experience on our site as comfortable as possible, we use cookies. Cookies help us improve our services and better offer you content that may be interesting and useful to you.",
  "src.components.organisms.CookieModal.link": "More",
  "src.components.organisms.CookieModal.accept": "Accept all",
  "src.components.organisms.CookieModal.reject": "Reject",
  "error.phone.required": "Phone number must contain 9 characters",
  "error.personal.required": "This field is required.",
  "error.name.required": "Enter your first and last name",
  "error.email.required": "Enter an email in the correct format",
  "error.faktura.required": "Invoice is required",
  "error.comment.required": "This field is required",
  "src.components.routes.pages.ThankYouAffiliate.title": "Thank you affiliate",
  "src.components.routes.pages.ThankYou.title": "Thank you",
  "src.components.organisms.MenuBlock.titleFirst": "What meals",
  "src.components.organisms.MenuBlock.titleSecond": "await you with us?",

  "src.components.organisms.MenuBlock.tab5": "Friday",
  "src.components.organisms.MenuBlock.tab4": "Thursday",
  "src.components.organisms.MenuBlock.tab3": "Wednesday",
  "src.components.organisms.MenuBlock.tab2": "Tuesday",
  "src.components.organisms.MenuBlock.tab1": "Monday",
  "src.components.organisms.MneuBlock.food1.title":
    "Coconut French toast with maple syrup and grapes",

  "src.components.organisms.MneuBlock.food2.title": "Fruit salad with cashews",

  "src.components.organisms.MneuBlock.food3.title":
    "Roast beef with honey-mustard sauce, potato wedges",
  "src.components.organisms.MneuBlock.food4.title":
    "Quiche with spinach leaves and Balkan cheese",

  "src.components.organisms.MneuBlock.food5.title":
    "Italian risotto with oyster mushrooms, white wine, and parmesan",
  "src.components.organisms.MneuBlock.food6.title":
    "Oatmeal with coconut milk, blueberries, and dark chocolate",

  "src.components.organisms.MneuBlock.food7.title":
    "Pear with nuts and chocolate",

  "src.components.organisms.MneuBlock.food8.title":
    "Chicken shashlik, baked potato slices, BBQ sauce",

  "src.components.organisms.MneuBlock.food9.title":
    "Turkey ham rolls on salad leaves with cashews and parmesan",
  "src.components.organisms.MneuBlock.food10.title":
    "Tacos with Mexican beans, grated cheddar cheese, iceberg lettuce, and tomatoes",
  "src.components.organisms.MneuBlock.food11.title":
    "Greek yogurt with forest fruits and honey",

  "src.components.organisms.MneuBlock.food12.title":
    "Old Czech poppy seed pancakes with plum sauce, cottage cheese, and grated gingerbread",
  "src.components.organisms.MneuBlock.food13.title":
    "Chicken steak Caprese, baked potato slices",

  "src.components.organisms.MneuBlock.food14.title":
    "Huevos rancheros with chickpeas",
  "src.components.organisms.MneuBlock.food15.title":
    "Salmon with broccoli, spinach, and tomatoes",
  "src.components.organisms.MneuBlock.food16.title":
    "Egg spread with cottage cheese and chives, dark bread",

  "src.components.organisms.MneuBlock.food17.title":
    "Cottage cheese dessert with blueberries",

  "src.components.organisms.MneuBlock.food18.title": "Lasagne Bolognese",
  "src.components.organisms.MneuBlock.food19.title":
    "Coconut pancakes with cream cheese and Provencal vegetables",
  "src.components.organisms.MneuBlock.food20.title":
    "Chicken medallions on thyme bulgur with baked carrot fries and grilled zucchini",

  "src.components.organisms.MneuBlock.food21.title":
    "Baked omelet with cheddar and parsley",

  "src.components.organisms.MneuBlock.food22.title": "Raw nut-date balls",
  "src.components.organisms.MneuBlock.food23.title":
    "Indian chicken Satay with peanut sauce and Basmati rice",

  "src.components.organisms.MneuBlock.food24.title":
    "Sandwich with chicken and cream cheese",

  "src.components.organisms.MneuBlock.food25.title": "Vietnamese soup Pho Bo",
  "src.components.organisms.MneuBlock.breakfast": "Breakfast",
  "src.components.organisms.MneuBlock.snack1": "Snack 1",
  "src.components.organisms.MneuBlock.snack2": "Snack 2",
  "src.components.organisms.MneuBlock.lunch": "Lunch",
  "src.components.organisms.MneuBlock.dinner": "Dinner",
  "src.component.organism.BannerBlock.title": "Order your meal boxes today.",
  "src.components.organisms.ReviewBlock.title1": "Adam Pechar",
  "src.components.organisms.ReviewBlock.description1":
    "One of the best investments I've made recently, I can only recommend it! Smooth cooperation, balanced diet, and great taste. The best diet box I've tried so far. I feel great after eating, and I always look forward to the next course. A crucial step for a healthy lifestyle. 👍 In one word, PERFECT",
  "src.components.organisms.ReviewBlock.title2": "Renata Vydrová",
  "src.components.organisms.ReviewBlock.description2":
    "The food is very good. :) And I no longer have to worry about what to cook each day. For me, it's TOP!",
  "src.components.organisms.ReviewBlock.title3": "Anton Lancevič",
  "src.components.organisms.ReviewBlock.description3": "I can only recommend!",
  "src.components.organisms.ReviewBlock.title4": "Hana Císlerová",
  "src.components.organisms.ReviewBlock.description4":
    "The best meal boxes I've ever tried. There was plenty of food, so I shared it with my son, and it ended up being cheaper than buying and cooking groceries.",
  "src.components.organisms.ReviewBlock.title5": "Margita Novotná",
  "src.components.organisms.ReviewBlock.description5":
    "Portions more than sufficient, very varied, tasty. Perfect service, arrangement, and additional recommendations with a helpful advisor without a flaw. I recommend it.",
  "src.components.organisms.ReviewBlock.title6": "Kristýna Burdová",
  "src.components.organisms.ReviewBlock.description6":
    "The meal boxes are absolutely great, they saved me during home offices when I didn't have time to cook, let alone something healthy. Salesperson Jitka helped me plan the optimal calorie intake so that I wasn't hungry and also lost a bit of weight. I was pleasantly surprised that Jitka asked about my eating and exercise habits and then tailored the meal boxes to my needs, which are very tasty. I recommend it!",
  "src.components.organisms.ReviewBlock.title7": "Jan Vítek",
  "src.components.organisms.ReviewBlock.description7":
    "The fish with the veggies was amazing! 👌",

  "src.components.organisms.ReviewBlock.title8": "Jan Kříž",
  "src.components.organisms.ReviewBlock.description8":
    "Always great taste and presentation, exactly tailored to me, excellent service around, for me a perfect service, and I'm finally fed so that I don't have to look for food elsewhere. Additionally, during the COVID situation, NutritionPro also solved the situation for people around me, who can stay and eat at home when they don't feel safe going out to shop. So thumbs up, and not just for this year, it's a clear choice. Thanks for it!!",
  "src.components.organisms.ReviewBlock.title9": "Lev Lyapeikov",
  "src.components.organisms.ReviewBlock.description9":
    "Great service! I have ordered twice for stock. Couriers arrived on time, and the food quality is excellent. My wife is at home with a 6-month-old baby, and there is no time for cooking. A 2000-calorie diet is enough for my wife all day, and I take lunch with me to work. A very profitable solution, cheaper than buying products in the store + saving time. Today I made an order for 12 days (pleasantly surprised by the low prices), for almost two weeks I'm free from shopping. Thank you so much for the yummy food and services!",
  "src.components.organisms.ReviewBlock.title10": "Nicole Ponce",
  "src.components.organisms.ReviewBlock.description10":
    "For someone who is a very picky eater, I would have to say that the meals from NutritionPro have exceeded my expectations and more. It makes my day so much easier to know that I have my meals prepared and ready every day, straight to my door. I would highly recommend it.",
  "src.components.organisms.ReviewBlock.title11": "Aneta Benova",
  "src.components.organisms.ReviewBlock.description11":
    "Very tasty and varied food :)",

  "src.components.organisms.ReviewBlock.title12": "Antoine Bourg",
  "src.components.organisms.ReviewBlock.description12":
    "Super satisfied, the recipes are simple yet diverse, and delightful. Feels like home cooking, and the results are seen very early on. Would recommend it to anyone looking for a tailored nutrition plan!!",
  "src.components.organisms.ReviewBlock.title13": "Olina Moroz",
  "src.components.organisms.ReviewBlock.description13":
    "(Original) Мне очень понравилось. Меню разнообразное и, самое главное, вкусное. Советую! (Translation) I really liked it. The menu is varied and, most importantly, delicious. I recommend it!",
  "src.components.organisms.ReviewBlock.title14": "Ondřej Staněk",
  "src.components.organisms.ReviewBlock.description14":
    "Excellent meal boxes. They don't repeat much, and you often get to try exotic foods you wouldn't normally come across. Portions are great and balanced. I'm definitely not hungry, and I'm naturally losing weight slowly.",
  "src.components.organisms.ReviewBlock.title15": "Tereza Žatečková",
  "src.components.organisms.ReviewBlock.description15":
    "I've been getting the meal boxes for a while now, and they still maintain their quality. The meals are varied, fresh, and the delivery is always on time. The quality/price ratio is appropriate. I recommend it👍",
  "src.components.organisms.ReviewBlock.title16": "Viktor Nyitray",
  "src.components.organisms.ReviewBlock.description16":
    "I am very satisfied with the meal boxes. They are not the classic meals from a canteen packed into boxes, but the food is really tasty! I recommend at least trying it :)",
  "src.components.organisms.ReviewBlock.title17": "Irena Rabochova",
  "src.components.organisms.ReviewBlock.description17":
    "The food is very tasty and balanced, made from fresh ingredients. Time-saving and weight loss. The service is top-notch, especially the delivery.",
  "src.components.organisms.ReviewBlock.title18": "Lucie Bartošová",
  "src.components.organisms.ReviewBlock.description18":
    "The food is absolutely great, but there's one thing even better – the delivery staff, especially one lady who always brightens my day, she's just perfect.",
  "src.components.organisms.ReviewBlock.title19": "Tereza Hofmanová",
  "src.components.organisms.ReviewBlock.description19":
    "Very good meal boxes, I like the variety of the meals. A clear choice for me during the busy periods when I don't have time to cook. I RECOMMEND!",
  "src.components.organisms.ReviewBlock.title20": "Zdenka Svobodova",
  "src.components.organisms.ReviewBlock.description20":
    "Very tasty food, very diverse in terms of ingredients. And you get to try some unusual food as well. Some combinations seemed strange to me (like lentils with fish), but they were always really well prepared, and despite the initial strangeness, the flavors worked well together. You can choose between sweet or savory snacks. I have a sweet tooth ;) and these snacks fully satisfy my cravings while still helping me lose some weight. At first, I struggled with the small portions, but that was due to my previous overeating. Within three days, I got used to it, and after a month, I can say that the portions are just right, diverse, and most importantly, they taste good. I don't even feel like I'm on a diet ;) and I look forward to the meals. The delivery is also great, three times a week, even in the late hours, and the lady who delivers to me is very kind. Thank you and keep up the good work ;)",
  "src.components.organisms.ReviewBlock.title21": "Linda Dlabačová",
  "src.components.organisms.ReviewBlock.description21": "New menu",
  "src.components.organisms.ReviewBlock.title22": "Maldonado David",
  "src.components.organisms.ReviewBlock.description22": "Super tasty food",
  "src.components.organisms.ReviewBlock.title23": "Matěj Schultz",
  "src.components.organisms.ReviewBlock.description23":
    "The best price/quality ratio, you've helped me immensely, thank you so much!!",

  "src.components.organisms.ReviewBlock.title24": "Klára Onderková",
  "src.components.organisms.ReviewBlock.description24":
    "Always timely delivery and great service, thanks for saving my time Nutrition Pro!!",
  "src.components.organisms.ReviewBlock.title25": "Aneta Pecková",
  "src.components.organisms.ReviewBlock.description25":
    "Possibly the first service of this kind that didn't disappoint me in the first week, on the contrary, it's fantastic!!",
  "src.components.organisms.ReviewBlock.title26": "Gregory Pelec",
  "src.components.organisms.ReviewBlock.title27":
    "It's been a while since I started my plan with you, and surprisingly, every meal was very delicious, and the kilos finally went down!! Highly recommend the Home Office pack to those who spend every day in the office like I do.",
  "src.components.organisms.ReviewBlock.title28": "Jitka Beranová",
  "src.components.organisms.ReviewBlock.description28":
    "Coming to the end of my first month with you, and I already feel a thousand times better, minus 3 kilos, and finally have time and energy. You are absolutely awesome!",
  "src.components.organisms.ReviewBlock.title29": "Jan Kříž",
  "src.components.organisms.ReviewBlock.description29":
    "Always great taste and presentation, just right for me, excellent service around it, for me, the perfect service, and finally, I'm fed well enough that I don't have to look for additional food elsewhere. Moreover, during the COVID situation, NutritionPro solved the issue for people around me who can stay and eat at home when they don't feel safe going out to shop. So thumbs up, and not just for this year, it's the clear choice. Thanks for it!!",
  "src.components.organisms.ReviewBlock.title30": "Eldar Alizade",
  "src.components.organisms.ReviewBlock.description30":
    "The food tastes great, I'm not hungry, and most importantly, I've lost a few kilos in a month! That was the main goal. Moreover, the consultants are helpful, and the communication is excellent. So everything is great, I recommend it!",
  "src.components.organisms.ReviewBlock.title31": "Káčko Béčko",
  "src.components.organisms.ReviewBlock.description31":
    "A pleasant surprise, very satisfied (I can compare it with competitors). The food is to my taste, I've ordered again.",
  "src.components.organisms.ReviewBlock.title32": "Patrick Beznoska",
  "src.components.organisms.ReviewBlock.description32":
    "Great service and food too",

  "src.components.organisms.ReviewBlock.title33": "Alan Pock",
  "src.components.organisms.ReviewBlock.description33":
    "This is awesome, after a month I really like it, mostly because I don't have to worry and constantly think about what to eat.",
  "src.components.organisms.ReviewBlock.title34": "Pavel Jiranek",
  "src.components.organisms.ReviewBlock.description34":
    "Tasty, quick, and neat solution for your daily eating habits!",

  "src.components.organisms.ReviewBlock.title35": "Petra Dolanská",
  "src.components.organisms.ReviewBlock.description35":
    "Luxurious food, great communication, and just the right portions. I can only RECOMMEND.",

  "src.components.organisms.ProgramBlock.meat.description1": "fully balanced",
  "src.components.organisms.ProgramBlock.meat.description2":
    "with a rich palette of ingredients",

  "src.components.organisms.ProgramBlock.meat.description3":
    "emphasizes variety",

  "src.components.organisms.ProgramBlock.meat.description4":
    "minerals and vitamins",

  "src.components.organisms.ProgramBlock.meat.description5": "fiber.",
  "src.components.organisms.ProgramBlock.meat.description6": " 3-5 courses",
  "src.components.organisms.ProgramBlock.meat.description7":
    "option to swap dishes",

  "src.components.organisms.ProgramBlock.meat.description8":
    "try some of our vegetarian dishes",

  "src.components.organisms.ProgramBlock.meat.description9":
    "seasonal ingredients,",

  "src.components.organisms.ProgramBlock.meat.description10": "top reviews",
  "src.components.organisms.ProgramBlock.meat.description11":
    "findings and research in line with nutritional standards",

  "src.components.organisms.ProgramBlock.meat.description12":
    "nutrition specialists.",

  "src.components.organisms.ProgramBlock.meat.description13":
    "caloric options,",

  "src.components.organisms.ProgramBlock.meat.description14":
    "current body weight, reduce body weight",

  "src.components.organisms.ProgramBlock.meat.description15":
    " gain muscle mass.",

  "src.components.organisms.ProgramBlock.vege.description1":
    "don't want meat and fish.",
  "src.components.organisms.ProgramBlock.vege.description2":
    "a diverse range of ingredients",
  "src.components.organisms.ProgramBlock.vege.description3":
    "sufficient protein, fiber, minerals, and vitamins.",

  "src.components.organisms.ProgramBlock.vege.description4": "3-5 courses",
  "src.components.organisms.ProgramBlock.active.description1":
    "active athletes",

  "src.components.organisms.ProgramBlock.active.description2":
    "higher energy and nutrient intake.",

  "src.components.organisms.ProgramBlock.active.description3":
    "rich in protein",
  "src.components.organisms.ProgramBlock.active.description4":
    "lower fat content.",

  "src.components.organisms.ProgramBlock.active.description5":
    "all nutrients in optimal proportions",

  "src.components.organisms.ProgramBlock.fish.description1":
    "without fish and fish products.",

  "src.components.organisms.ProgramBlock.fish.description2":
    "a wide selection of tasty dishes,",

  "src.components.organisms.ProgramBlock.fish.description3":
    "nutritionally valuable food.",

  "src.components.organisms.ProgramBlock.fish.description4": "3-5 courses",
  "src.components.organisms.ProgramBlock.office.description1":
    "two-course menu",

  "src.components.organisms.ProgramBlock.office.description2":
    "choose according to your preferences.",

  "src.components.organisms.WhyNutrition.item1.title":
    "Option to swap any course",

  "src.components.organisms.WhyNutrition.item2.title":
    "Our meals average a 4.6/5 rating",

  "src.components.organisms.WhyNutrition.item3.title": "New menu every day",
  "src.components.organisms.WhyNutrition.item4.title":
    "A personalized meal plan to meet your goals",
  "src.components.organisms.WhyNutrition.item5.title":
    "The most accurate delivery time",

  "src.components.organisms.WhyNutrition.item6.title":
    "The most flexible delivery",
  "src.components.organisms.WhyNutrition.item7.title":
    "Nutritional consultation included",

  "src.components.organisms.WhyNutrition.item8.title":
    "The most professional meal preparation",

  "src.components.organisms.WhyNutrition.item1.description":
    "Each of us is unique, and not everyone likes the same things. That's not a problem with us. You can swap any course for another dish at no extra cost.",
  "src.components.organisms.WhyNutrition.item2.description":
    "We actively collect feedback from clients on every meal and continuously improve our menu based on it.",
  "src.components.organisms.WhyNutrition.item3.description":
    "Our menu consists of more than 300 dishes. Over 4 weeks, you won't see any dish twice. You can look forward to your favorites again next month.",
  "src.components.organisms.WhyNutrition.item4.description":
    "Thanks to our special algorithm, we can tailor a meal plan to each customer's needs.",
  "src.components.organisms.WhyNutrition.item5.description":
    "We deliver within two-hour time slots that you can change at your convenience. We bring the food right to your door at the time you choose.",
  "src.components.organisms.WhyNutrition.item6.description":
    "Don't wait at home all evening for the courier. You can flexibly adjust the delivery time and place until almost the last minute. Just call or text us.",
  "src.components.organisms.WhyNutrition.item7.description":
    "From day one, you're on the right path. Our nutritional consultants and nutrition expert will help you with your dietary goals for free.",
  "src.components.organisms.WhyNutrition.item8.description":
    "Your meals are prepared under the supervision of a chef, a nutrition specialist, and a production technologist, ensuring the highest quality of the prepared dishes.",
  "src.components.organisms.WhyNutrition.redBtn": "Calculate your price",
  "src.components.organisms.WhyNutrition.titleFirst": "Why NutritionPro",
  "src.components.organisms.WhyNutrition.titleSecond": "box diet?",
  "src.components.organisms.CityDeliveryBlock.list.description1":
    "We deliver in the green area.",

  "src.components.organisms.CityDeliveryBlock.list.description2":
    "Evening delivery between {clock}",

  "src.components.organisms.CityDeliveryBlock.list.descriptionPlzen":
    "You can choose from three delivery time slots, no more chasing couriers all evening!",
  "src.components.organisms.CityDeliveryBlock.list.description3":
    "Have your plans changed? You can flexibly change the time and place on the day of delivery until 10:00 AM.",
  "src.components.organisms.CityDeliveryBlock.list.title": "How does it work?",
  "src.components.organisms.CityDeliveryBlock.title":
    "The most accurate and flexible delivery in",

  "src.components.organisms.CallMeBlock.call": "Call me",
  "src.components.organisms.CallMeBlock.order": "Order online",
  "src.components.organisms.CallMeModal.title": "Call me",
  "src.components.organisms.CallMeModal.programTitle":
    "Non-binding consultation",

  "src.components.organisms.CallMeModal.programDescription":
    "Need help choosing, or is something unclear? Leave us your contact, and we will get back to you.",
  "src.components.organisms.DesktopHeader.program": "Programs",
  "src.components.organisms.DesktopHeader.customerPortal": "Customer portal",
  "src.components.organisms.DesktopHeader.menu": "Menu",
  "src.components.organisms.NotFoundBlock.title": "Page not found",
  "src.components.organisms.NotFoundBlock.btn": "Back to home page",
  "src.components.organisms.Delivery.brno.point": "1 pick-up point",
  "src.components.organisms.Delivery.plzen.point": "1 pick-up point",
  "src.components.organisms.Delivery.liberec.point": "1 pick-up point",
  "src.components.organisms.Delivery.hradec.point": "1 pick-up point",
  "src.components.organisms.Delivery.mlda.point": "1 pick-up point",
  "src.components.organisms.Delivery.kladno.point": "1 pick-up point",
  "src.components.organisms.Delivery.kladno.info":
    "One-hour window notification by SMS",

  "src.components.routes.pages.Terms.description":
    "společnosti Nutritionpro s.r.o. se sídlem Inovační 122, 252 41 Zlatníky- Hodkovice, IČO 07752971",
  "src.components.routes.pages.Terms.content1.title": "I. Vymezení pojmů",
  "src.components.routes.pages.Terms.content2.title": "II. Objednávka",
  "src.components.routes.pages.Terms.content3.title":
    "III. Změna/storno objednávky",

  "src.components.routes.pages.Terms.content4.title": "IV. Dodání",
  "src.components.routes.pages.Terms.content5.title":
    "V. Vady programu NutritionPro a reklamace",
  "src.components.routes.pages.Terms.content6.title":
    "VI. Cena stravování programu NutritionPro",

  "src.components.routes.pages.Terms.content7.title": "VII. Závěrečná ujednání",
  "src.components.routes.pages.Terms.content1.description1":
    "Pro účely těchto Všeobecných obchodních podmínek mají níže uvedené termíny následující význam:",
  "src.components.routes.pages.Terms.content1.description2":
    "Společnost: Nutritionpro s.r.o. se sídlem Inovační 122, 252 41 Zlatníky- Hodkovice, IČO 07752971(dále jen „Provozovatel“)",
  "src.components.routes.pages.Terms.content1.description3":
    "E-mail: info@nutritionpro.cz",
  "src.components.routes.pages.Terms.content1.description4":
    "Program NutritionPro je programem pravidelného zdravého stravování, který zahrnuje přípravu a dodávání denních setů pokrmů podle klientem vybraného a objednaného počtu denních/týdenních/měsíčních cyklů Provozovatelem, a to na místo určené klientem nebo na odběrné místo. (dále jen „Program NutritionPro“)",
  "src.components.routes.pages.Terms.content1.description5":
    "Denní set pokrmů je vyváženou kombinací stravy na jeden den dodávanou klientovi Provozovatelem. Denní set pokrmů bude individuálně přizpůsoben na optimální příjem makroživin podle údajů z úvodního dotazníku vyplněného klientem a vstupních údajů o složení těla. Pokrm v každém denním setu je přehledně uspořádán, označen, zabalen a takto jednou za dva dny dodáván klientovi na místo jím určeném nebo na odběrné místo.",

  "src.components.routes.pages.Terms.content1.description6":
    "Klientem je osoba objednávající Program NutritionPro, jakožto osoba mající zájem o nabízené služby spočívající v pravidelném stravování formou nutričně vyvážených a hodnotných potravin. Klient se zavazuje za tyto služby uhradit provozovateli sjednanou odměnu. (dále jen „Klient“)",
  "src.components.routes.pages.Terms.content2.description1":
    "1. Objednávku Programu NutritionPro lze provést následujícím způsobem:",

  "src.components.routes.pages.Terms.content2.description2":
    "2. Objednávku Programu NutritionPro je třeba uskutečnit nejméně 3 pracovní dny přede dnem, na který má být Program NutritionPro dodán. Každá učiněná objednávka je závazným návrhem na uzavření kupní smlouvy. Obsahem uzavřené kupní smlouvy (dále jen “Smlouva”) je povinnost Provozovatele dodávat Klientovi pokrmy, které si vybral během objednávky a povinnost Klienta uhradit mu za dodání těchto pokrmů kupní cenu. Smlouva je uzavřena na dobu určitou, přičemž délka této doby je Klientem vybrána při podání objednávky.",
  "src.components.routes.pages.Terms.content2.description3":
    "3. Pokud klient trpí alergií na některou z potravin, je povinen neprodleně telefonicky informovat provozovatele, přičemž bude současně dohodnut postup řešení takové situace. Klient je také povinen sdělit provozovateli jiné zdravotní obtíže, které mohou souviset s konzumací určitých pokrmů nebo surovin.",
  "src.components.routes.pages.Terms.content3.description1":
    "1. Klient je povinen sdělit Provozovateli změny objednávky, jako je zejména zrušení objednávky Programu NutritionPro či změna objednaného programu, ve lhůtě nejméně 3 pracovní dny přede dnem, na který má být Program NutritionPro dodán. Změnám, o kterých Klient informuje Provozovatele v kratší lhůtě, než je uvedeno v předchozí větě, nebude ze strany Provozovatele vyhověno, Program NutritionPro bude Klientovi řádně dodán a Klient je povinen uhradit Provozovateli cenu za takto dodaný Program NutritionPro v plné výši.",

  "src.components.routes.pages.Terms.content3.description2":
    "2. Pokud Klient změní/zruší objednávku v pracovní době zákaznické podpory, tj. Po-Pá od 10:00 - 12:00 a od 13:00 - 18:00 na telefonním čísle +420 226 288 200, nejpozději však 3 celé pracovní dny přede dnem doručení (nepočítá se však samotný den doručení, tj. den doručení minus 3 celé pracovní dny), Provozovatel provede změnu/odhlášku takto změněné/zrušené objednávky.",
  "src.components.routes.pages.Terms.content4.description1":
    "1. Provozovatel se zavazuje vyrobit Program NutritionPro dle objednávky Klienta a dopravit do místa určeného Klientem a tam odevzdat Klientovi způsobem uvedeným v odst. 2 tohoto článku.",
  "src.components.routes.pages.Terms.content4.description2":
    "2. Řidič rozvážející Program NutritionPro předá denní set pokrmů Klientovi, osobě Klientem určené nebo jiné osobě přítomné v místě určeném Klientem jako místo dodávky Programu NutritionPro. Okamžikem odevzdání Programu NutritionPro Klientovi přechází na Klienta nebezpečí škody na Programu. Pokud není na místě určeném Klientem jako místo dodání žádná osoba, které by mohl být Program NutritionPro předán a klient není telefonicky dostupný, bude Program NutritionPro uložen na skladu a Klient bude informován o možnostech vyzvednutí.",
  "src.components.routes.pages.Terms.content4.description3":
    "3. Denní set pokrmů je dodáván v chlazeném stavu a musí být až do jeho konzumace udržován v chladu, tedy v teplotě max. do 4 °C. Provozovatel nenese žádnou odpovědnost za jakost Programu NutritionPro, pokud po jeho odevzdání Klientovi dojde k porušení skladovacích podmínek.",
  "src.components.routes.pages.Terms.content4.description4":
    "4. Denní set pokrmů musí být spotřebován nejpozději do dne uvedeného na štítku. Po otevření je nutné pokrm ihned spotřebovat.",
  "src.components.routes.pages.Terms.content4.description5":
    "5. Denní set pokrmů bude na určené místo dodáván v časovém rozmezí od 6 do 10h nebo od 17 do 22 hodin, nebude-li pro konkrétní lokalitu Klientovi Provozovatelem sděleno jiné časové rozmezí. Klient si je vědom, že v mimořádných případech se může doba dodání změnit s ohledem na dopravní situaci a jiné obdobné události. O této změně bude klient informován telefonicky",
  "src.components.routes.pages.Terms.content4.description6":
    "6. V případě potřeby Klienta na změnu dohodnutého času dodání na místo určené klientem, je klient tuto změnu oprávněn provést prostřednictvím emailu nebo na telefonním čísle +420 226 288 200 do 12 hodin dne dodávky denního setu pokrmů.",
  "src.components.routes.pages.Terms.content5.description1":
    "1. Provozovatel není odpovědný za vady způsobené: neoprávněným zásahem, nevhodným skladováním, neodbornou manipulací, v důsledku použití zboží k jiným než určeným účelům, vlivem živelné pohromy nebo zásahem vyšší moci po předání zboží Klientovi.",
  "src.components.routes.pages.Terms.content5.description2":
    "2. Při převzetí zboží je kupující povinen zkontrolovat, zda dodaný pokrm nevykazuje vady způsobené přepravou. Pokud dodaný pokrm vykazuje vady, je kupující povinen dodaný pokrm nepřevzít a informovat o tom neprodleně prodávajícího prostřednictvím emailu na adrese: info@nutritionpro.cz nebo prostřednictvím formuláře {button}",
  "src.components.routes.pages.Terms.content5.description3":
    "3. Klient je oprávněn reklamovat pokrm pouze v případě, že z reklamovaného pokrmu nezkonzumoval / jinak neodstranil více jak 10 % z porce pokrmu. Jestliže reklamaci prodávající posoudí jako oprávněnou, bude vyřešena buďto výměnou za jiný pokrm ve stejné cenovéhladině, nebo ve formě tzv. Kreditů, které může Klient využít v další objednávce. Při reklamaci kvality pokrmů může Provozovatel požadovat svoz pokrmu řidičem určeným Provozovatelem.",
  "src.components.routes.pages.Terms.content5.description4":
    "4. Klient je povinen oprávněnost svého nároku, který uplatní prostřednictvím formuláře, nebo zasláním na uvedený e-mail, dle odst. 2 výše doložit také přiloženou fotografií, která dokládá namítanou vadu.",
  "src.components.routes.pages.Terms.content6.description1":
    "1. Klient se zavazuje objednaný Program NutritionPro v místě jím určeném od Provozovatele převzít a uhradit Provozovateli za tento Program řádně a včas Kupní cenu.",
  "src.components.routes.pages.Terms.content6.description2":
    "2. Provozovatel vyúčtuje Kupní cenu Klientovi fakturou s datem splatnosti do 5 dnů ode dne vystavení faktury. Provozovatel je oprávněn vystavit Klientovi fakturu v den, kdy si Klient Program NutritionPro objedná a zašle Klientovi fakturu e-mailem.",
  "src.components.routes.pages.Terms.content6.description3":
    "3. The client will pay the Purchase Price to the Operator based on the issued invoice by bank transfer to the Operator's bank account under the variable symbol specified in the invoice.",
  "src.components.routes.pages.Terms.content6.description4":
    "4. Kupní cena se považuje za uhrazenou okamžikem jejího připsání na bankovní účet Provozovatele.",
  "src.components.routes.pages.Terms.content6.description5":
    "5. V případě prodlení s úhradou Kupní ceny může Provozovatel požadovat po Klientovi zaplacení úroku z prodlení.",
  "src.components.routes.pages.Terms.content6.description6":
    "6. V případě prodlení s úhradou Kupní ceny může Provozovatel odmítnout dodávku objednaného Programu až do úplné úhrady Kupní ceny za předchozí dodávky Programu NutritionPro",
  "src.components.routes.pages.Terms.content6.description7":
    "7. Provozovatel si vyhrazuje právo dodat Program NutritionPro až po uhrazení Kupní ceny Klientem.",
  "src.components.routes.pages.Terms.content7.description1":
    "1. Veškeré podmínky Smlouvou výslovně neupravené se řídí obecně závaznými právními předpisy České republiky, zejména ustanoveními zákona č. 89/2012 Sb., občanského zákoníku, v platném znění.",
  "src.components.routes.pages.Terms.content7.description2":
    "2. Tyto závazné smluvní podmínky jsou platné a účinné ode dne jejich zveřejnění na internetových stránkách provozovatele. Provozovatel je oprávněn tyto Všeobecné smluvní podmínky jednostranně měnit. O jakýchkoliv změnách nebo doplnění těchto závazných smluvních podmínek bude provozovatel klienty předem informovat. Pokud klient neprojeví se změnou nesouhlas do 30 dnů ode dne jejího oznámení, má se za to, že se změnou smluvních podmínek souhlasí. V případě, že Klient se změnou Všeobecných obchodních podmínek nesouhlasí, je oprávněn Smlouvu vypovědět s výpovědní dobou o délce 14 dní. V případě, ukáže-li se následně některé z ustanovení těchto smluvních podmínek jako neplatné či neúčinné, neznamená tato skutečnost neplatnost smluvních podmínek jako celku. Provozovatel se zavazuje takovéto neplatné či neúčinné ujednání nahradit jiným ujednáním a informovat o této změně klienta.",
  "src.components.routes.pages.Terms.content7.description3":
    "3. Akceptací těchto smluvních podmínek klientem se považují za závazně sjednané mezi ním a provozovatelem a klient nemá k obsahu Smlouvy a smluvních podmínek žádných výhrad.",
  "src.components.routes.pages.Terms.btn": "ODKAZ ZDE",
  "src.components.organisms.forms.TermsForm.btn": "Submit",
  "src.components.organisms.TermsModal.title": "Complaint form",
  "src.components.organisms.forms.TermsForm.checkbox":
    "Consent to the processing of personal data for marketing purposes.",

  "src.components.routes.pages.Gdpr.description1":
    "Nutritionpro s.r.o., located at Inovační 122, 252 41 Zlatníky-Hodkovice, IČO 07752971, email: info@nutritionpro.cz, phone: +420 777 510 839 (hereinafter referred to as the 'Operator') in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (hereinafter referred to as: 'GDPR') respects the principles of personal data protection and processes only those personal data that serve to provide the service selected by the customer and for the period necessary for this purpose.",
  "src.components.routes.pages.Gdpr.description2":
    "The Operator also processes personal data of representatives of customers (contracting parties) and also potential customers who are interested in the services of the Operator. The purposes of processing personal data as well as information on legal titles, legitimate interests, and information regarding the fact that personal information is processed only based on the client's free and active consent are always contained in the concluded contract or in the terms and conditions published on the Operator's website. Personal data is also processed to fulfill the legal obligations of the Operator arising from the Tax Code or other legal regulations, as well as for other purposes that pursue the legitimate interest of the Operator, namely:",
  "src.components.routes.pages.Gdpr.description3":
    "1. recording clients who have been contacted by the Operator's sales representatives in connection with the offer to conclude a contract, or",
  "src.components.routes.pages.Gdpr.description4":
    "2. for the purpose of fraud prevention, purposes {info}",
  "src.components.routes.pages.Gdpr.description4.info":
    "of checking established technological processes",

  "src.components.routes.pages.Gdpr.description5":
    "3. recruitment of new employees who provide the Operator with their personal data during the interview process. The data of unsuccessful applicants is retained for a maximum of 6 months for the purpose of possibly using it to contact them with another job offer/participation in another or repeated selection process for a job position unless otherwise agreed by the parties,",
  "src.components.routes.pages.Gdpr.description6":
    "4. direct marketing, which means the processing of personal data of existing customers for the purpose of direct contact with a business offer or research. The Operator points out that if the customer objects to direct marketing for a particular service, the Operator will no longer process the customer's personal data for this purpose, nor will it contact the customer/send business offers,",
  "src.components.routes.pages.Gdpr.description7":
    "5. enforcement and defense of the Operator's claims, or",

  "src.components.routes.pages.Gdpr.description8":
    "for the purpose of enforcement and assertion of legal claims in complaints, legal disputes, and enforcement proceedings.",
  "src.components.routes.pages.Gdpr.description9":
    "The information contained in this document supplements the information provided to customers in the concluded contract.",
  "src.components.routes.pages.Gdpr.description10":
    "Identity and contact details of the controller",

  "src.components.routes.pages.Gdpr.description11":
    "Nutritionpro s.r.o., located at Inovační 122, 252 41 Zlatníky-Hodkovice, IČO 07752971, email: info@nutritionpro.cz, phone: +420 777 510 839",
  "src.components.routes.pages.Gdpr.description12":
    "Contact details of the Data Protection Officer",

  "src.components.routes.pages.Gdpr.description13":
    "In all matters related to the processing of your personal data, our Data Protection Officer is available to you.",
  "src.components.routes.pages.Gdpr.description14":
    "The Data Protection Officer can be contacted by email at info@nutritionpro.cz",

  "src.components.routes.pages.Gdpr.description15":
    "Notice of the right to object",

  "src.components.routes.pages.Gdpr.description16":
    "In all cases of processing personal information by the Operator, the client has the right to object to such processing. The objection will then be assessed, and if there are appropriate legal reasons, it will be upheld.",
  "src.components.routes.pages.Gdpr.description17": "Security",
  "src.components.routes.pages.Gdpr.description18":
    "All processed personal data is secured with appropriate organizational and technical measures.",
  "src.components.routes.pages.Gdpr.description19":
    "Recipients of Personal Data",
  "src.components.routes.pages.Gdpr.description20":
    "A recipient of personal data is a natural or legal person, public authority, agency, or other entity to which the personal data is disclosed, whether a third party or not. Public authorities that may receive personal data in the context of a specific inquiry in accordance with the law of a Member State are not regarded as recipients; the processing of such personal data by these public authorities must comply with the applicable data protection rules for the specific purposes of the processing.",
  "src.components.routes.pages.Gdpr.description21":
    "The Operator transfers personal data only to the following recipients who participate in the delivery of goods/services/payment processing based on the contract or provide marketing services.",
  "src.components.routes.pages.Gdpr.description22":
    "The controller does not intend to transfer personal data to a third country (outside the EU) or an international organization.",
  "src.components.routes.pages.Gdpr.description23":
    "The basic period during which personal data is stored is always specified in the contract concluded between the client and the Operator.",
  "src.components.routes.pages.Gdpr.description24":
    "The basic period during which personal data is stored is always specified in the contract concluded between the client and the Operator.",
  "src.components.routes.pages.Gdpr.description25":
    "The personal data of potential clients who have provided their personal data but have not concluded a contract is stored for 1 year for record-keeping purposes.",
  "src.components.routes.pages.Gdpr.description26":
    "For record-keeping purposes, the personal data of clients is stored for 10 years from the termination of the contract.",
  "src.components.routes.pages.Gdpr.description27": "Stored Data",
  "src.components.routes.pages.Gdpr.description28.info": "Contact Details:",
  "src.components.routes.pages.Gdpr.description28":
    "{info} Name, address, phone number, email address, company identification number (IČO). Processing is necessary for the performance of a contract to which the data subject is a party or to take steps at the request of the data subject prior to entering into a contract, or processing is necessary for the purposes of legitimate interests pursued by the controller or a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject that require protection of personal data. The purpose is for the Operator to know what the Client requires and why they contacted the Operator.",
  "src.components.routes.pages.Gdpr.description29.info1": "Location Data:",
  "src.components.routes.pages.Gdpr.description29.info2":
    "The reasons and purposes are similar to those mentioned above for contact details, particularly for delivery purposes.",
  "src.components.routes.pages.Gdpr.description29":
    "{info1} Address, postal code, city, state. {info2}",
  "src.components.routes.pages.Gdpr.description30.info1": "Communication Data:",
  "src.components.routes.pages.Gdpr.description30.info2":
    "The reasons and purposes are similar to those mentioned above for contact details, particularly so that the Operator can contact the Client for the purpose of fulfilling the contract, with appropriate and respectful communication, or for resolving issues related to delivery or handling complaints.",
  "src.components.routes.pages.Gdpr.description30":
    "{info1} Name, email address, phone number. {info2}",

  "src.components.routes.pages.Gdpr.description31.info1": "Payment Data:",
  "src.components.routes.pages.Gdpr.description31.info2":
    "The reasons and purposes are similar to those mentioned above for contact details, particularly so that the Operator can track payments and associate them with the relevant orders or for handling complaints.",
  "src.components.routes.pages.Gdpr.description31":
    "{info1} Payment method. {info2}",
  "src.components.routes.pages.Gdpr.description32.info1": "Delivery Data:",

  "src.components.routes.pages.Gdpr.description32.info2":
    "The reasons and purposes are similar to those mentioned above for contact details, particularly for delivering orders. Only the necessary data for delivery is provided to our drivers.",
  "src.components.routes.pages.Gdpr.description32":
    "{info1} Name, delivery address, phone number, customer identification number. {info2}",
  "src.components.routes.pages.Gdpr.description33": "Other Stored Data",
  "src.components.routes.pages.Gdpr.description34":
    "All other information, personal data provided by clients about other individuals with whom they cooperate or whose data is necessary for fulfilling the contractual relationship, is also stored. The client is obliged to inform these individuals similarly about the transfer of their personal data.",
  "src.components.routes.pages.Gdpr.description35":
    "The Operator also collects personal data obtained during phone calls or on social networks (e.g., Facebook, Twitter, etc.). Phone calls may be monitored and recorded to ensure the quality and proper functioning of our services. Recordings may be stored for up to six months for internal purposes only. The reasons and purposes are similar to those mentioned above for contact details.",
  "src.components.routes.pages.Gdpr.description36":
    "Specific Purposes of Data Processing",
  "src.components.routes.pages.Gdpr.description37.info": "Drivers/Deliverers:",
  "src.components.routes.pages.Gdpr.description37":
    "{info} These individuals, whether employed or in a similar relationship, or third parties entrusted with delivering the order, are provided with the necessary data for order delivery. The category of personal data is delivery data in accordance with Article 6(1)(b) GDPR.",
  "src.components.routes.pages.Gdpr.description38.info1": "Direct Marketing:",
  "src.components.routes.pages.Gdpr.description38.info2":
    "The category of personal data is",

  "src.components.routes.pages.Gdpr.description38":
    "{info1} By providing your email address, you agree to receive our commercial offers via email. The processing of personal data (phone numbers, email addresses, etc.) for the purposes of direct marketing (for sending commercial communications) is processing carried out for the legitimate interest according to Article 6(1)(f) GDPR. We hereby inform our clients of their right to object under Article 21(2) GDPR to the processing of personal data for direct marketing purposes. If the Client raises this objection, personal data may no longer be processed for this purpose (this is the so-called opt-out principle). See below, revoking your consent. {info2} contact details and location data. If the client does not agree from the beginning with receiving commercial communications, emails, etc., as part of direct marketing, they should notify the Operator of this request during their first order or send this communication in advance to the email address provided below. See revoking your consent.",
  "src.components.routes.pages.Gdpr.description39.info": "Targeting.",
  "src.components.routes.pages.Gdpr.description39":
    "{info} Targeting involves customizing, i.e., disabling, activating, and modulating advertising banners on websites for certain target groups to most effectively communicate to the website user/potential customer on an individualized basis.",
  "src.components.routes.pages.Gdpr.description40":
    "Retargeting. Retargeting refers to the use of cookies when revisiting the Operator's website. This feature can also be disabled by deactivating add-ons in your web browser or limited by deleting cookies. The category of personal data is contact details.",
  "src.components.routes.pages.Gdpr.description41":
    "Cookies. A cookie is a short text file that a visited website saves in your browser. It allows the website to record information about your visit, making your next visit easier and the website more useful. Most browsers allow you to control how cookies are set and used when browsing and to delete cookies and browsing data. Your browser may also offer management of cookies on individual websites. You can express your disagreement with our use of cookies by sending a relevant message to the email address provided below. We will address your request without undue delay, but the Operator points out that this disagreement will only take effect within the web browser from which you create your disagreement.",
  "src.components.routes.pages.Gdpr.description42.info":
    "Revoking Your Consent",

  "src.components.routes.pages.Gdpr.description42":
    "{info} can be done by stating that you revoke your consent to the use of personal data to info@nutritionpro.cz. Send any objections to the processing of personal data to this address as well.",
  "src.components.routes.pages.Gdpr.description43": "Your Rights",
  "src.components.routes.pages.Gdpr.description44":
    "1. Under the conditions set out in the GDPR, you also have the right to lodge a complaint with the Data Protection Authority if you believe your right to personal data protection has been violated.",
  "src.components.routes.pages.Gdpr.description45":
    "2. You also have the right to lodge a complaint with the Data Protection Authority if you believe your right to personal data protection has been violated.",
  "src.components.routes.pages.Gdpr.description46": "Final Provisions",

  "src.components.routes.pages.Gdpr.description47":
    "1. By submitting an order via the online order form, you confirm that you have been informed of the personal data protection terms and that you fully accept them.",
  "src.components.routes.pages.Gdpr.description48":
    "2. You agree to these terms by checking the consent box via the online form. By checking the consent box, you confirm that you have been informed of the personal data protection terms and that you fully accept them.",
  "src.components.routes.pages.Gdpr.description49":
    "The controller is entitled to change these conditions. The new version of the personal data protection terms will be published on the controller's website, or the new version of these terms will be sent to the email address you provided to the controller.",
  "src.components.routes.pages.Gdpr.description50":
    "We reserve the right to change this privacy statement in accordance with legal regulations. We will inform you of any significant changes, such as changes in purpose or new purposes for processing.",
  "src.components.organisms.forms.TermsForm.name": "Name and Surname",
  "src.components.organisms.forms.TermsForm.email": "Email",
  "src.components.organisms.forms.TermsForm.fakutra": "Invoice Number",
  "src.components.organisms.forms.TermsForm.comment": "Reason for Complaint",
  "src.components.organisms.forms.TermsForm.comment2":
    "Your Request for Resolution",
  "src.components.routes.pages.VyzkousejMenu.title": "TryMenu",
  "src.components.molecules.DeliveryCard.btn": "Show More",
  "src.components.atoms.KalorieItem.kalorie": "kcal",
  "src.components.organisms.OrderOnlineFirstStep.people":
    "I am ordering food for",
  "src.components.organisms.OrderOnlineFirstStep.menu": "Select Your Menu",
  "src.components.organisms.OrderOnlineFirstStep.program": "What is your goal?",
  "src.components.organisms.OrderOnlineFirstStep.duration": "Program Duration",
  "src.components.organisms.OrderOnlineFirstStep.duration.description":
    "How long do you want the food to be delivered?",
  "src.components.organisms.OrderOnlineFirstStep.dayWeek": "Week Length",
  "src.components.organisms.OrderOnlineFirstStep.dayWeek.description":
    "How many days a week will we deliver?",
  "src.components.molecules.OnlineMenuSlider.meat.title":
    "NutritionPro Classic",
  "src.components.molecules.OnlineMenuSlider.vege.title": "NutritionPro Vege",
  "src.components.molecules.OnlineMenuSlider.fish.title": "Menu without fish",
  "src.components.molecules.OnlineProgramBlock.lose.title":
    "I want to lose weight",
  "src.components.molecules.OnlineProgramBlock.gain.title":
    "I want to gain muscle",
  "src.components.molecules.OnlineProgramBlock.maintanace.title":
    "I want to maintain my weight",
  "src.components.molecules.OnlineProgramBlock.lunch.title":
    "I don't want to cook at home",
  "src.components.molecules.ProgramWeekDuration.dni": " {day} days",
  "src.components.molecules.ProgramWeekDuration.info6": "(Monday to Saturday)",
  "src.components.molecules.ProgramWeekDuration.info5": "(Monday to Friday)",
  "src.components.molecules.ProgramWeekDuration.info4": "(Monday to Thursday)",
  "src.components.molecules.ProgramWeekDuration.info6.mob": "pondělí až sobota",
  "src.components.molecules.ProgramWeekDuration.info5.mob": "pondělí až pátek",
  "src.components.molecules.ProgramWeekDuration.info4.mob":
    "pondělí až čtvrtek",
  "src.components.molecules.OnlinePersonCard.sebe": "Myself",
  "src.components.molecules.OnlinePersonCard.people": "2 people",
  "src.components.molecules.OnlinePersonCard.lidi": "4+ people",
  "src.components.molecules.OnlinePersonCard.tooltip":
    "For orders for more people, use the 'I'm Interested' form",

  "src.components.molecules.OnlinePersonCard.info":
    "Při objednání 2-4 jidelničků na stejnou adresu se platí pouze jedna doprava.",

  "src.components.molecules.OrderRightCard.call": "I'm Interested",
  "src.components.molecules.OrderRightCard.nebo": "or",
  "src.components.templates.OnlineOrderMobileTemplate.close": "Cancel",
  "src.components.organisms.PromoCodeModal.title": "Promo Code",
  "src.components.organisms.PromoCodeModal.field": "Enter Promo Code",
  "src.components.organisms.forms.PromoCodeForm.btn": "Apply",

  "src.components.organisms.forms.PromoCodeForm.errorMessage":
    "This promo code is not valid",
  "src.components.molecules.OrderRightCard.call.title": "I'm Interested",
  "src.components.organisms.OnlineOrderSecondStep.info": "Personal Information",
  "src.components.organisms.OnlineOrderSecondStep.name": "Name and Surname",
  "src.components.organisms.OnlineOrderSecondStep.email": "Email",
  "src.components.organisms.OnlineOrderSecondStep.psc": "Postal Code",
  "src.components.organisms.OnlineOrderSecondStep.ico": "IČO ",
  "src.components.organisms.OnlineOrderSecondStep.dic": "DIČ",
  "src.components.organisms.OnlineOrderSecondStep.factura": "Billing Address",
  "src.components.organisms.OnlineOrderSecondStep.companyName": "Company Name",
  "src.components.organisms.OnlineOrderSecondStep.switchCompany":
    "Order for company",

  "src.components.organisms.OnlineOrderSecondStep.companyInfo":
    "Billing Information",

  "src.components.molecules.OrderSecondStepRightCard.title": "Order Price",
  "src.components.molecules.OnlineOrderAddressCheck.city": "City",
  "src.components.molecules.OnlineOrderAddressCheck.pickUpPoint":
    "Pick-up Point",

  "src.components.molecules.OnlineOrderAddressCheck.address": "Address",
  "src.components.organisms.OnlineOrderThiredStep.calendar.title":
    "Program Start Date",

  "src.components.organisms.OnlineOrderThiredStep.calendar.description":
    "Choose the date when you want to start receiving our boxes. We will deliver the first boxes the day before the selected date.",

  "src.components.organisms.OnlineOrderThiredStep.time.title":
    "Delivery Time Window",

  "src.components.organisms.OnlineOrderThiredStep.time.prague":
    "Choose the most suitable 2-hour delivery window. Our couriers deliver food boxes from 18:00 to 22:00.",
  "src.components.organisms.OnlineOrderThiredStep.time.prague-area":
    "Choose the most suitable 2-hour delivery window. Our couriers deliver food boxes from 18:00 to 22:00.",
  "src.components.organisms.OnlineOrderThiredStep.time.kladno":
    "Choose the most suitable 2-hour delivery window. Our couriers deliver food boxes from 18:00 to 22:00.",
  "src.components.organisms.OnlineOrderThiredStep.time.plzen":
    "Choose the most suitable 2-hour delivery window. Our couriers deliver food boxes from 17:00 to 21:00.",
  "src.components.organisms.OnlineOrderThiredStep.platba": "Choose Payment",
  "src.components.organisms.OnlineOrderThiredStep.platba.gopay":
    "Secure payment provided by",

  "src.components.molecules.PromoCodeApprove.promoCode": "Promo Code",
  "src.components.molecules.PromoCodeApprove.remove": "Remove",
  "src.components.molecules.OnlineOrderThiredStepSidebar.discount":
    "Volume Discount",

  "src.components.molecules.OnlineOrderThiredStepSidebar.confirm": "Order",
  "src.components.organisms.TimeSlotModal.title": "Delivery Time Window",
  "src.components.organisms.TimeSlotModal.field":
    "Choose the most suitable 2-hour window.",

  "src.components.organisms.TimeSlot.label": "Choose Delivery Time",
  "src.components.organisms.CalendarOrder.label": "Choose Date",
  "src.components.organisms.CalendarModal.title": "Program Start Date",
  "src.components.organisms.CalendarModal.btn": "Confirm Date",
  "src.components.organisms.OrderOnlineFirstStep.porcel.description":
    "Kolik chodů ti budeme vozit",

  "src.components.organisms.OrderOnlineFirstStep.porcel": "Počet jídel denně",
  "src.components.organisms.HowCook.tab1.title": "Most Flexible Menu",
  "src.components.organisms.HowCook.tab2.title": "Highest Food Quality",
  "src.components.organisms.HowCook.tab3.title": "Most Accurate Delivery",
  "src.components.organisms.HowCook.tab1.title1":
    "Choose up to 4 meals per course",

  "src.components.organisms.HowCook.tab1.descritpion1":
    "Do you crave a sweet or savory breakfast? Do you prefer a light salad over a hot dinner? It's up to you!",
  "src.components.organisms.HowCook.tab1.title2":
    "Customize your menu in the client zone",

  "src.components.organisms.HowCook.tab1.description2":
    "Easily adjust everything in our intuitive user interface, and see how changes affect the distribution of macronutrients.",
  "src.components.organisms.HowCook.tab1.title3":
    "Adjust intake according to yourself",
  "src.components.organisms.RateBlock.subTitle":
    "10 meals for 2 days with a discount of {greenTitle}",
  "src.components.organisms.BackBoxesRateBlock.subTitle1":
    "{boldTitle} on your next order!",

  "src.components.organisms.BackBoxesRateBlock.subTitleBold1": "15% Discount",
  "src.components.organisms.BackBoxesRateBlock.subTitle2":
    "Promo Code: {boldTitle}",

  "src.components.organisms.BackBoxesRateBlock.subTitleBold2": "ZPATKY1500",
  "src.components.organisms.HowCook.tab1.description3":
    "Calories can be freely chosen from 800-2600 kcal with an accuracy of 100 kcal and the number of courses from 2 to 5 with the option to purchase an extra portion.",
  "src.components.organisms.HowCook.tab1.title4":
    "Supplement your diet with extra vitamins",
  "src.components.organisms.HowCook.tab1.descripton4":
    "You can also buy vitamin packs in a convenient one-day package with your meal.",
  "src.components.molecules.OnlineOrderAddressCheck.tab1":
    "Delivery to Address",
  "src.components.molecules.OnlineOrderAddressCheck.tab2":
    "Delivery to Pick-up Point",
  "src.components.organisms.HowCook.tab2.title1":
    "Don't worry about preparation quality",
  "src.components.organisms.HowCook.tab2.descritpion1":
    "Food is prepared in the most modern kitchens under the control of our technologist and quality specialist, according to the highest hygiene standards.",
  "src.components.organisms.HowCook.tab2.title2": "Enjoy maximum freshness",
  "src.components.organisms.HowCook.tab2.description2":
    "Throughout the time from production to delivery, we measure the maintenance of the temperature chain for maximum quality and freshness of the food upon delivery.",
  "src.components.organisms.HowCook.tab2.title3": "Eat only the best calories",
  "src.components.organisms.HowCook.tab2.description3":
    "The composition of the meals is controlled by our nutrition specialist, and we make sure that the calories in the food come from the highest quality sources, without using artificial sweeteners.",
  "src.components.organisms.HowCook.tab2.title4": "Try a variety of meals",
  "src.components.organisms.HowCook.tab2.descripton4":
    "Our menu has something from every cuisine, from lasagna to butter chicken, but don't worry, if you don't like exotic food, you can always replace the meal, for example with a healthy goulash.",
  "src.components.organisms.HowCook.tab3.title1": "Choose when we will deliver",
  "src.components.organisms.HowCook.tab3.descritpion1":
    "In Prague, we deliver within 2-hour windows that you choose yourself, so you don't have to wait all day to see when the courier will arrive.",
  "src.components.organisms.HowCook.tab3.title2":
    "Flexibly change place and time",
  "src.components.organisms.HowCook.tab3.description2":
    "You can still change the delivery place and time on the same day or have it delivered to a pick-up point.",
  "src.components.organisms.HowCook.tab3.title3":
    "Use one of our pick-up points",
  "src.components.organisms.HowCook.tab3.description3":
    "You can pick up your order at one of our 10 pick-up points, conveniently located in high-traffic areas such as fitness centers.",
  "src.components.organisms.HowCook.tab3.title4":
    "Have it delivered to more than 10 cities in the Czech Republic",
  "src.components.organisms.HowCook.tab3.descripton4":
    "You can also change the delivery location between cities. So if you're on a business trip or visiting relatives, we'll deliver your boxes where you need them.",
  "src.components.moelecules.BenefitLabels.text1":
    "Choice of {head} for each course according to taste.",
  "src.components.moelecules.BenefitLabels.subText1": "4 meals",
  "src.components.moelecules.BenefitLabels.text2":
    "We deliver food in {head}, or to pick-up points.",
  "src.components.moelecules.BenefitLabels.subText2": "2-hour windows",
  "src.components.moelecules.BenefitLabels.text3":
    "Calories through {head}, not sauces.",
  "src.components.moelecules.BenefitLabels.subText3": "proteins",
  "src.components.moelecules.BenefitLabels.text4": "Pause {head}, just call.",
  "src.components.moelecules.BenefitLabels.subText4": "anytime for free",
  "src.components.molecules.InfoHeader.text":
    "I'm on a different box diet and are you considering switching? Order now on NutritionPro and get a voucher for 1000 CZK to {info}",
  "src.components.molecules.InfoHeader.info": "Alza.cz",
  "src.components.atoms.HowCookItem.info": "More info",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms1":
    "By continuing, you agree to the",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms1.link":
    "terms and conditions",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms2":
    "You will find out how we handle your personal data ",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms2.link": "here.",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDay":
    "Price per menu (excluding delivery):",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceTotal":
    "Price for the entire period:",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDelivery":
    "Delivery price per day:",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDeliveryDemo":
    "Delivery price:",
  "src.components.routes.pages.PaymentNotCompleted.title":
    "Payment not complited",
  "src.components.routes.pages.ThankYouOrder.title": "Order complited",
  "src.components.organisms.PaymentModal.succsesTitle":
    "Děkujeme, vaši objednávku jsme úspěšně přijali.",
  "src.components.organisms.PaymentModal.succsesText":
    "Mezitím jsme vám do e-mailu poslali veškeré informace k dokončení platby bankovním převodem.",
  "src.components.organisms.Faq.menu.content1.bold": "meal box",
  "src.components.organisms.Faq.menu.content2.bold": " meal box",
  "src.components.organisms.Faq.menu.content3.bold": "meal box",
  "src.components.organisms.Faq.menu.content3.boldInfo": "ukázku jídelníčku",
  "src.components.organisms.Faq.materials.content1.bold": "meal box delivery ",
  "src.components.organisms.Faq.materials.content2.bold": "meal box delivery ",
  "src.components.organisms.Faq.materials.content3.bold": "meal box delivery ",
  "src.components.organisms.Faq.materials.content4.bold": "meal box delivery ",
  "src.components.organisms.Faq.delivery.content1.bold": "meal box delivery ",
  "src.components.organisms.Faq.delivery.content2.bold": "meal box delivery ",
  "src.components.organisms.Faq.delivery.content3.bold": "meal box",
  "src.components.organisms.Faq.delivery.content3.boldInfo":
    "online kalkulačce",
  "src.components.organisms.Faq.delivery.content4.bold": "meal box delivery ",
  "src.components.organisms.Faq.delivery.content6.bold": "meal box delivery ",
  "src.components.organisms.Faq.delivery.content7.bold": "meal box delivery ",
  "src.components.organisms.Faq.delivery.content9.bold": "meal box delivery ",
  "src.components.organisms.Faq.consumption.content1.bold":
    "meal box delivery ",
  "src.components.organisms.Faq.consumption.content2.bold":
    "meal box delivery ",
  "src.components.organisms.Faq.consumption.content3.bold":
    "meal box delivery ",
  "src.components.organisms.Faq.client.subTitle1":
    "Can I change my meal plan if my needs change?",
  "src.components.organisms.Faq.client.content1":
    "Yes, our meal box service is very flexible. If you need to change your meal plan or adjust portion sizes, you can easily do so through our customer portal or by contacting our customer support.",
  "src.components.organisms.Faq.client.subTitle2":
    "What customization options do you offer for your meal box delivery?",
  "src.components.organisms.Faq.client.content2":
    "We allow you to customize your menu according to your preferences, allergies, and other requirements. You can choose the number of courses, omit certain ingredients, or adjust portion sizes to suit your needs.",
  "src.components.organisms.Faq.client.subTitle3":
    "How can I take a break from the meal box service?",
  "src.components.organisms.Faq.client.content3":
    "You can take a break at any time without any cost. Simply call {info} or email our customer service at info@nutritionpro.cz.",
  "src.components.organisms.Faq.client.content3.bold": "226 288 200",
  "src.components.organisms.Faq.client.subTitle4":
    "How can I contact customer support if I have a problem or question?",
  "src.components.organisms.Faq.client.content4":
    "Our customer support is available every weekday from 08:00 to 18:00. You can reach us by phone at  {info}, by email at info@nutritionpro.cz, or through our customer portal, where we strive to respond as quickly as possible.",
  "src.components.organisms.Faq.client.content4.bold": "226 288 200",
  "src.components.organisms.Faq.client.subTitle5":
    "How do you handle feedback and complaints?",
  "src.components.organisms.Faq.client.content5":
    "Your satisfaction is our priority. We address each piece of feedback or complaint individually and strive to find a solution that meets your needs.",
  "src.components.organisms.Faq.delivery.prague": "celé Praze a okolí",
  "src.components.organisms.Faq.delivery.chech": "středních Čechách",
  "src.components.organisms.Faq.delivery.kladno": "Kladně",
  "src.components.organisms.Faq.delivery.plzen": "Plzni",
  "src.components.organisms.Faq.delivery.liberec": "Liberci",
  "src.components.organisms.Faq.delivery.mlda": "Mladé Boleslavi",
  "src.components.organisms.Faq.delivery.jablonec": "Jablonci nad Nisou",
  "src.components.organisms.Faq.delivery.paradubice": "Pardubicích",
  "src.components.organisms.Faq.delivery.hradec": "Hradci Králové",
  "src.components.organisms.OnlineOrderThiredStep.emptyTimeSlot":
    "Jídlo doručujeme od 17:00 do 22:00 v úterý, čtvrtek a neděli.",

  "src.components.organisms.OnlineOrderThiredStep.emptyTimeSlotHradec":
    "Jídlo doručujeme od 17:00 do 22:00 v úterý, čtvrtek a neděli.",

  "src.components.organisms.FaqPraha.menu.content1.bold":
    "krabičková dieta Praha",
  "src.components.organisms.FaqPraha.menu.content1.boldInfo":
    "krabičková dieta",
  "src.components.organisms.FaqPraha.menu.content1":
    "Naše {info} se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše {boldInfo} je navržena tak, aby odpovídala vašim individuálním potřebám a cílům, což je něco, co běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.FaqPraha.menu.content2":
    "Jídelníček pro naši {info} je sestavován speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že dosáhnete požadovaných výsledků.",
  "src.components.organisms.FaqPraha.menu.content2.bold":
    "krabičkovou dietu Praha",
  "src.components.organisms.FaqPraha.menu.content3.boldInfo":
    "krabičkovou dietu Praha.",
  "src.components.organisms.FaqPraha.menu.content3.bold": "krabičková dieta",
  "src.components.organisms.FaqPraha.menu.content3":
    "Naše {info} je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, takže se naše jídla nikdy nepřejí. Podívejte se na ukázku jídelníčku, který nabízíme pro {boldInfo}",
  "src.components.organisms.FaqPraha.materials.content1":
    "Pro naši {info} používáme pouze kvalitní a čerstvé suroviny od prověřených českých dodavatelů. Kvalita a čerstvost surovin je pro nás základem zdravého stravování.",
  "src.components.organisms.FaqPraha.materials.content1.bold":
    "krabičkovou dietu Praha",
  "src.components.organisms.FaqPraha.materials.content2":
    "Ano, všechna jídla v rámci naší {info} jsou připravována z čerstvých surovin, které kupujeme v den přípravy. Po uvaření jsou jídla šokově zchlazena a hermeticky zabalena, což zajišťuje jejich maximální čerstvost.",
  "src.components.organisms.FaqPraha.materials.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.materials.content3":
    "Příprava jídel pro naši {info} probíhá v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek, a ve čtvrtek na pátek a sobotu.",
  "src.components.organisms.FaqPraha.materials.content3.bold":
    "krabičkovou dietu",
  "src.components.organisms.FaqPraha.materials.content4":
    "Ano, naše {info} zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",
  "src.components.organisms.FaqPraha.materials.content4.bold":
    "krabičková dieta Praha",
  "src.components.organisms.FaqPraha.delivery.content1":
    "Jakékoliv změny objednávky v rámci naší {info}, včetně pozastavení dovozu, je nutné nahlásit nejpozději do 11:00 tři pracovní dny před plánovaným doručením. Tato pravidla platí, protože pro každé vaření nakupujeme čerstvé suroviny na přesný počet zákazníků.",
  "src.components.organisms.FaqPraha.delivery.content1.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content2":
    "Pokud se vaše objednávka {info} vztahuje na dva dny, je možné zrušit pouze celou dvoudenní objednávku. Zrušení pouze jednoho dne není možné.",
  "src.components.organisms.FaqPraha.delivery.content3.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content3":
    "Cena naší {info} je stanovena individuálně podle vašeho osobního stravovacího plánu a denního kalorického příjmu. Cena se může lišit v závislosti na vašich cílech, ať už se jedná o hubnutí, nabírání svalové hmoty nebo udržování váhy. Kalkulaci ceny si můžete nechat vypočítat v naší online kalkulačce.",
  "src.components.organisms.FaqPraha.delivery.content4":
    "Po dokončení objednávky naší {info} vám zašleme fakturu na váš e-mail. Můžete také zvolit platbu kartou.",
  "src.components.organisms.FaqPraha.delivery.content4.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content6":
    "Naši {info} rozvážíme po {prague}, {chech} , {kladno}, {plzen}, {liberec}, {mlda}, {jablonec}, {paradubic} a {hradec}.",
  "src.components.organisms.FaqPraha.delivery.content6.bold":
    "krabičkovou dietu Praha",
  "src.components.organisms.FaqPraha.delivery.content7":
    "Jídla z naší {info} doručujeme obden – v úterý, čtvrtek a neděli, vždy mezi 16:00 a 22:00 hodinou.",
  "src.components.organisms.FaqPraha.delivery.content7.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content8":
    "Můžete si vybrat konkrétní 2hodinové okno pro doručení (17:00 - 22:00). Přesný čas dovozu bude upřesněn s řidičem v den dovozu.",
  "src.components.organisms.FaqPraha.delivery.content9":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 12:00.",
  "src.components.organisms.FaqPraha.delivery.content9.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.consumption.content1":
    "Některá jídla z naší {info} jsou určena k ohřevu, jiná ke konzumaci za studena. Pro lepší chuť však doporučujeme jídla ohřát. Informace o tom, které jídlo je třeba ohřát, zjistíte po načtení čárového kódu na krabičce.",
  "src.components.organisms.FaqPraha.consumption.content1.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.consumption.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.consumption.content2":
    "Pokud ohříváte jídla z naší {info} v mikrovlnné troubě, doporučujeme propíchnout dírky do folie. Čas ohřevu se liší podle druhu jídla a velikosti porce, ale doporučujeme použít střední výkon (600–700 W) pro rovnoměrné prohřátí.",
  "src.components.organisms.FaqPraha.consumption.content3":
    "Jídla z naší {info} doporučujeme skladovat v lednici, ale můžete je vzít s sebou i do práce nebo na cesty. Jídla vydrží několik hodin mimo lednici bez ztráty chuti nebo kvality.",
  "src.components.organisms.FaqPraha.consumption.content3.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.client.content1":
    "Ano, naše {info} je velmi flexibilní. Pokud potřebujete změnit své jídelníčky nebo upravit velikost porcí, můžete to snadno provést přes náš zákaznický portál nebo kontaktováním naší zákaznické podpory.",
  "src.components.organisms.FaqPraha.client.content1.bold":
    "krabičková dieta Praha",
  "src.components.organisms.FaqPraha.client.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.client.content2":
    "Umožňujeme vám přizpůsobit si menu podle vašich preferencí, alergií a dalších požadavků. Můžete si zvolit počet chodů, vynechat určité ingredience nebo upravit porce dle vaší potřeby v rámci naší {info}.",
  "src.components.organisms.FaqPraha.client.content3":
    "Pauzu si můžete dát kdykoliv, nestojí to nic, stačí jen zavolat na {info} nebo napsat e-mail na náš zákaznický servis {boldInfo}.",
  "src.components.organisms.FaqPraha.client.content3.bold": " 226 288 200",
  "src.components.organisms.FaqPraha.client.content3.boldInfo":
    "info@nutritionpro.cz.",
  "src.components.organisms.FaqPraha.client.content4":
    "Naše zákaznická podpora je vám k dispozici každý pracovní den od 08:00-18:00. Můžete nás kontaktovat telefonicky {info}, e-mailem {boldInfo}, nebo prostřednictvím našeho zákaznického portálu, kde se snažíme odpovědět co",
  "src.components.organisms.FaqPraha.client.content4.bold": "226 288 200",
  "src.components.organisms.FaqPraha.client.content4.boldInfo":
    "info@nutritionpro.cz",
  "src.components.moelecules.BenefitLabels.text3.praha":
    "Kalorie přes {head}, ne omáčky.",
  "src.components.moelecules.BenefitLabels.text4.praha":
    "Pauza {head}, stačí zavolat.",
  "src.components.moelecules.BenefitLabels.text1.praha":
    "Výběr ze {head} u každého chodu podle chuti",
  "src.components.moelecules.BenefitLabels.text2.praha":
    "Rozvážíme krabickou dietu v Praze ve {head}, nebo na odběrné místa.",

  "src.components.organisms.ProgramBlock.titleSecond.praha":
    " jeden z našich programů",
  "src.components.organisms.ProgramBlock.description.praha":
    "Užij si pestrou chuťovou paletu bez omezení. V rámci všech programů máš nyní možnost libovolné chody vyměnit.",
  "src.components.organisms.MenuBlock.titleFirst.praha": "Jaká jídla",
  "src.components.organisms.MenuBlock.titleSecond.praha": " tě s námi čekají?",
  "src.components.organisms.ComplateMenu.titleFirst.praha":
    "Nezávazně vyzkoušej naši",
  "src.components.organisms.ComplateMenu.titleSecond.praha":
    "naši krabičkovou dietu na 2 dny",
  "src.components.organisms.ComplateMenu.description.praha":
    "Leave us your contact, and we will call you within two hours during business hours to arrange everything within 10 minutes.",
  "src.components.organisms.ReviewBlock.description.praha":
    " Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.titleFirst.praha": "Proč NutritionPro - ",
  "src.components.moelecules.BenefitCityLabels.text3":
    "Calories through proteins, not sauces.",
  "src.components.moelecules.BenefitCityLabels.text4":
    "Pause anytime for free, just give us a call.",
  "src.components.moelecules.BenefitCityLabels.text1":
    "Choose from {head} for each course according to your taste.",
  "src.components.moelecules.BenefitCityLabels.subText2": "4 meals",
  "src.components.moelecules.BenefitCityLabels.text2":
    "Deliveries in the morning and evening, to your home or to a pick-up point.",
  "src.components.organisms.FaqPraha.titleFirst": "Často ",
  "src.components.organisms.FaqPraha.titleSecond": "kladené dotazy",
  "src.components.organisms.HowWorks.daily.praha":
    "Nastavíme tvůj denní příjem podle tvých cílů",
  "src.components.organisms.HowWorks.algorithm.praha":
    "Speciální algoritmus sestaví jídelníček na míru",
  "src.components.organisms.HowWorks.cooking.praha": "Jídlo uvaříme",
  "src.components.organisms.HowWorks.delivery.praha":
    "Dovezeme ti domů či do práce",
  "src.components.organisms.HowWorks.goal.praha":
    " Následně dohlédneme na to, abys svého cíle dosáhl | Krabičková dieta Praha",
  "src.components.organisms.HowWorks.titleFirst.praha": "Jak funguje naše ",
  "src.components.organisms.HowWorks.titleSecond.praha":
    "krabičková dieta v Praze?",

  "src.components.organisms.HowCook.tab3.descripton4.praha":
    "Zároveň si můžeš mezi městy i měnit místo dovozu, tedy pokud tě čeká pracovní cesta, či návštěva příbuzných, krabičky ti doručíme kam bude potřeba.",
  "src.components.organisms.HowCook.tab3.description3.praha":
    "Odebírat můžeš na jednom z našich 10 odběrných míst, pohodlně umístěných na frekventovaných místech jako jsou fitness centra. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.tab3.description2.praha":
    "Místo i čas dovozu si ještě ten samý den můžeš změnit, případně si nechat dovézt na odběrné místo. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.tab3.descritpion1.praha":
    "V Praze vozíme v 2 hodinových oknech, které si sám navolíš, tedy nemusíš každý den čekat, jak to zrovna vyjde kurýrovi.",
  "src.components.organisms.HowCook.tab2.descripton4.praha":
    " V jídelníčku máme z každé kuchyně něco, od lasagních po butter chicken, ale neboj, pokud ti exotika nevyhovuje, vždy si můžeš jídlo nahradit, třeba zdravým gulášem. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.tab2.description3.praha":
    "Složení jídel je kontrolováno naší nutriční specialistkou a dbáme na to, aby kalorie v jídle pocházeli z těch nejkvalitnějších zdrojů, bez použití umělých sladidel.",
  "src.components.organisms.HowCook.tab2.description2.praha":
    "Po celou dobu od výroby po doručení měříme zachování teplotního řetězce pro maximální kvalitu a čerstvost jídla při dodání.",
  "src.components.organisms.HowCook.tab2.descritpion1.praha":
    " Krabickovu Diety Praha připravujeme v nejmodernějších kuchyních pod kontrolou našeho technologa a kvalitáře, dle nejvyšších hygienických standardů.",
  "src.components.organisms.HowCook.tab1.descripton4.praha":
    "K jídlu si můžeš také dokoupit vitamínové balíčky v pohodlném jednodenním balení.",
  "src.components.organisms.HowCook.tab1.description3.praha":
    "Kalorie si můžeš libovolně volit od 800-2600 kcal s přesností na 100 kcal a počet chodů od 2 do 5 s možností dokoupit extra porci.",
  "src.components.organisms.HowCook.tab1.description2.praha":
    "Vše si navolíš jednoduše v našem intuitivním uživatelském prostředí, kde zároveň vidíš i jak změna ovlivní rozložení makronutrientů.",
  "src.components.organisms.HowCook.tab1.descritpion1.praha":
    "Máš chuť na sladkou, nebo slanou snídani? Dáš přednost lehkému salátu před teplou večeří? U nás je to na tobě!",

  "src.components.organisms.HowCook.tab1.title1.praha":
    "Vybírej až ze 4 jídel na každý chod",
  "src.components.organisms.HowCook.tab1.title2.praha":
    "Navol si jídelníček v klientské zóně",

  "src.components.organisms.HowCook.tab1.title3.praha":
    "Uzpůsob si příjem dle sebe",
  "src.components.organisms.HowCook.tab1.title4.praha":
    " Doplň si Krabickovu Diety Praha o extra vitamíny",

  "src.components.organisms.HowCook.tab2.title1.praha":
    "Neboj se o kvalitu přípravy",

  "src.components.organisms.HowCook.tab2.title2.praha":
    "Užij si maximální čerstvost",
  "src.components.organisms.HowCook.tab2.title3.praha":
    "Jez jen ty nejlepší kalorie",
  "src.components.organisms.HowCook.tab2.title4.praha":
    "Vyzkoušej rozmanitost jídel",
  "src.components.organisms.HowCook.tab3.title1.praha":
    "Krabičková dieta Praha - Vyber si kdy ti budeme vozit",

  "src.components.organisms.HowCook.tab3.title2.praha":
    "Krabičková dieta Praha - Flexibilně si změň místo a čas",
  "src.components.organisms.HowCook.tab3.title3.praha":
    "Krabičková dieta Praha - Využij jedno z našich odběrných míst",

  "src.components.organisms.HowCook.tab3.title4.praha":
    "Krabičková dieta Praha - Nech si přivézt do více než 10 měst v ČR",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDayDemo":
    "Total price",
  "src.components.molecules.OnlineConfirmCard.priceDemo": "{price} Kč",
  "src.components.atoms.PricePerDay.titleDemo": "Total price",
  "src.components.organisms.ComplateMenu.redBtn": "Order online",

  "src.components.templates.OnlineOrderMobileTemplate.title1":
    "Calculate the price",
  "src.components.templates.OnlineOrderMobileTemplate.title2":
    "Contact Details",
  "src.components.templates.OnlineOrderMobileTemplate.title3":
    "Doručení a platba",
  "src.components.templates.OnlineOrderMobileTemplate.description1":
    "There are just a few steps left to deliver the meal boxes.",
  "src.components.templates.OnlineOrderMobileTemplate.description2":
    "Please proceed by filling in your contact information.",
  "src.components.templates.OnlineOrderMobileTemplate.description3":
    "Dokončete objednávku vyplněním času rozvozu a výběrem platby.",
  "src.components.templates.OnlineOrderMobileTemplate.back": "Back",
  "src.components.templates.OnlineOrderDesctopTemplate.sidebarTitile":
    "Order summary",
  "src.components.molecules.Steps.firstStep": "Select program",
  "src.components.molecules.Steps.secondStep": "Contact Details",
  "src.components.molecules.Steps.lastStep": "Delivery and payment",
  "src.components.moelcules.OnlineOrderAddressCheck.city": "Enter City",
  "src.components.moelcules.OnlineOrderAddressCheck.pick":
    "Select a Pickup Location",

  "src.component.atoms.AddressInput.success": "Free shipping",
  "src.component.atoms.AddressInput.error":
    "We are sorry! We are not in this location yet.",
  "src.component.atoms.AddressInput.placeHolder": "Enter street and apt.numb",
  "src.component.atoms.AddressInput.button": "Confirm",
  "src.components.molecules.FixedMark.rate": "  rating",
  "src.component.molecules.ProgramDurationBlock.default": "Best",
  "src.component.molecules.ProgramDurationBlock.demo": "Discount 50%",
  "src.component.molecules.ProgramDurationBlock.price": "{price} CZK",
  "src.component.molecules.ProgramDurationBlock.day": "day",

  "src.components.organisms.PickUpPointsModal.text": "Collection points",

  "src.components.organisms.HomePgaeSliderDefault.title": "BOX DIET",
  "src.components.organisms.HomePgaeSliderDefault.promoCode":
    "FROM 94 CZK PER COURSE",
  "src.components.organisms.HomePgaeSliderDefault.priceBtn": "Order Online Now",
  "src.components.organisms.HomePgaeSliderDefault.description":
    "Finally, a meal box service that’s truly worth it!",
  "src.components.orhanisms.HomePageSliderDemo.btn": "Order Online Now",
  "src.components.orhanisms.HomePageSliderDemo.title":
    "Try it without obligation for 749 CZK",
  "src.components.orhanisms.HomePageSliderDemo.priceText":
    " A total of 10 courses await you, non-binding and with a discount ",
  "src.components.orhanisms.HomePageSliderDemo.price": " 45  %.",
  "src.components.orhanisms.HomePageSliderDemo.description":
    " Try it now so you don't miss out on the offer.",
  "src.component.molecules.ProgramDurationBlock.days": "days",
  "src.components.molecules.FixedMark.rated": "Top-rated",
  "src.components.molecules.FixedMark.boxes": "meal box service",
  "src.components.molecules.FixedMark.count": "positive customer reviews",
  "src.components.routes.pages.Terms.content1.description3.tel":
    "Tel: +420 226 288 200",
  "src.components.routes.pages.Terms.content2.description1.a":
    "a. na telefonním čísle +420 226 288 200, kdy k uzavření kupní smlouvy dojde potvrzenímobjednávky ze strany Provozovatele při daném hovoru,",
  "src.components.routes.pages.Terms.content2.description1.b":
    "b. prostřednictvím e-mailu na e-mailové adresy uvedené na internetových stránkách www.nutritionpro.cz, kdy k uzavření kupní smlouvy dojde potvrzením objednávky ze strany Provozovatele formou e-mailu, telefonicky nebo dodáním Programu NutritionPro (v případě opětovné dodávky Programu NutritionPro, kdy Klient reaguje novou objednávkou na e-mail Provozovatele oznamující blížící se konec předchozí objednávky),",
  "src.components.routes.pages.Terms.content2.description1.c":
    "c. prostřednictvím on-line formuláře umístěného na internetových stránkách www.nutritionpro.cz, kdy k uzavření kupní smlouvy dojde k potvrzením objednávky ze strany Provozovatele telefonicky nebo emailem.",
  "src.components.routes.pages.Terms.content4.description7":
    "7. Pokud je na vaší adrese možné zanechat objednávku na vašem pozemku, tzv. `na kliku`, je možné se domluvit s našimi operátory na této výjimce. Tímto způsobem předání zaniká odpovědnost Provozovatele za produkt, jeho kvalitu a případně odcizení již není předmětem naší odpovědnosti. Objednávky předané tímto způsobem budou fotodokumentovány kurýrem pro naši evidenci a případné prokázání splnění našich závazků.",
  "src.components.routes.pages.Terms.content4.description8":
    "8. Pokud Klient nepřebere svou objednávku setu pokrmů během smluveného rozvozu, tedy ve smluveném čase a místě, je Klient v případě vyzvání ze strany Provozovatele povinen uhradit Provozovateli paušální náhradu škody za každou nepřevzatou objednávku, a to ve výši 50 Kč za jedno nepřevzetí. Tato částka představuje paušalizovanou náhradu škody za vícepráce s tím spojené.",
  "src.components.routes.pages.Terms.content5.description5":
    "5. Klient je oprávněn odstoupit od Smlouvy do 14 dnů od okamžiku jejího uzavření. V tomto případě Provozovatel vrátí Klientovi zaslané prostředky za nevyužité/nevybrané dny objednávky zpět na jeho účet nejpozději do 30 dnů ode dne ukončení smlouvy.",
  "src.components.routes.pages.Terms.content5.description6":
    "6. Klient je oprávněn od Smlouvy odstoupit i po uplynutí lhůty uvedené v předchozím odstavci. Pokud však Klient odstoupí od Smlouvy po lhůtě uvedené v předchozím odstavci, je Provozovatel oprávněn účtovat Klientovi storno poplatek ve výši až 75% z nevyužitých/nevybraných dnů objednávky. Zbytek prostředků vrátí Provozovatel Klientovi zpět na jeho účet nejpozději do 30 dnů ode dne ukončení smlouvy.",
  "src.components.routes.pages.Terms.content5.description7":
    "7. Všechny reklamace budou ze strany Provozovatele vyhodnoceny do 30 dní od data jejího uplatnění.",
  "src.components.routes.pages.Terms.content5.description8":
    "8. Předmětem reklamace může být jak kvalita pokrmů, tak s tím spojené služby (rozvoz, úroveň komunikace apod.)",
  "src.components.routes.pages.Terms.content5.description9":
    "9. Provozovatel je oprávněn odstoupit od Smlouvy v případě, že je Klient v prodlení s úhradou jakéhokoli peněžitého plnění Provozovateli",

  "src.components.organisms.Faq.delivery.content8.text1":
    "Praha: you can choose from 2-hour windows, we deliver between 17:00 - 22:00",
  "src.components.organisms.Faq.delivery.content8.text2":
    "Plzeň: here we offer 3 time window options: ",
  "src.components.organisms.Faq.delivery.content8.text3":
    "17:00 – 20:00 | 18:00 – 21:00 | 19:00 – 21:00",
  "src.components.organisms.Faq.delivery.content8.text4":
    "Liberec: 17:00 – 22:00",
  "src.components.organisms.Faq.delivery.content8.text5":
    "Mladá Boleslav: 16:00 – 22:00",
  "src.components.organisms.Faq.delivery.content8.text6":
    "Jablonec nad Nisou: 18:00 – 22:00",
  "src.components.organisms.Faq.delivery.content8.text7":
    "Pardubice: 16:30 – 22:00",
  "src.components.organisms.Faq.delivery.content8.text8":
    "Hradec Králové: 16:30 –22:00",
  "src.components.routes.pages.SwitchBoxes.title": "Switch boxes",
  "src.components.molecules.SwitchCompainSlider.title1": "Objednej si program",
  "src.components.molecules.SwitchCompainSlider.title2": "Zašli nám fakturu",
  "src.components.molecules.SwitchCompainSlider.title3": "Získej voucher",
  "src.components.routes.pages.AceTerms.title": "Ace page",
  "src.components.molecules.OnlineOrderAddressCheck.checkbox":
    "From time to time we would like to send you discounts, offers and marketing information. A checked box means you agree. Don't worry, you can always unsubscribe later.",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms1.text1": " and ",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms1.link2":
    "personal data protection policy.",
  "src.components.routes.pages.DietBrno.HowCook.title":
    " krabičková dieta Brno?",
  "src.components.routes.pages.DietBrno.HowWorks.title":
    "funguje naše krabičková dieta v Brně?",
  "src.components.routes.pages.DietBrno.ReviewBlock.title":
    " Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Brno",

  "src.components.organisms.Faq.menu.content1.brno":
    "Naše krabičková dieta se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což je něco, co běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.subTitle1.brno":
    "V čem je naše krabičková dieta v Brně jiná?",

  "src.components.organisms.Faq.menu.subTitle2.brno":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Brně?",
  "src.components.organisms.Faq.menu.content2.brno":
    "Jídelníček pro naši krabičkovou dietu je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle3.brno":
    "Jak vypadá jídelníček v rámci krabičkové diety v Brně?",
  "src.components.organisms.Faq.menu.content3.brno":
    "Naše krabičková dieta je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, takže se naše jídla nikdy nepřejí. Podívejte se na ukázku jídelníčku, který nabízíme.",
  "src.components.organisms.Faq.materials.subTitle3.brno":
    "Kdy připravujete jídla pro krabičkovou dietu v Brně?",
  "src.components.organisms.Faq.materials.content3.brno":
    "Příprava jídel pro naši krabičkovou dietu v Brně probíhá v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",
  "src.components.organisms.Faq.materials.subTitle4.title":
    "Máte v nabídce i dezerty v rámci krabičkové diety Brno?",
  "src.components.organisms.Faq.materials.content4.title":
    "Ano, naše krabičková dieta zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",
  "src.components.molecules.OnlineOrderThiredStepSidebar.pluxeeConfirm":
    "I'm interested",

  "src.components.routes.pages.DietPlzen.HowCook.title":
    " NutritionPro - krabičková dieta Plzeň?",
  "src.components.routes.pages.DietPlzen.ReviewBlock.description":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Plzeň",
  "src.components.routes.pages.DietPlzen.HowWorks.title":
    "funguje naše krabičková dieta v Plzni?",
  "src.components.routes.pages.DietHradec.HowCook.title":
    " NutritionPro - krabičková dieta Hradec Králové?",
  "src.components.routes.pages.DietHradec.ReviewBlock.description":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Hradec Králové",
  "src.components.routes.pages.DietHradec.HowWorks.title":
    " funguje naše krabičková dieta v Hradci Králové?",
  "src.components.routes.pages.DietLiberec.HowCook.title":
    " NutritionPro - krabičková dieta Liberec?",
  "src.components.routes.pages.DietLiberec.ReviewBlock.description":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Liberec",
  "src.components.routes.pages.DietLiberec.HowWorks.title":
    "funguje naše krabičková dieta v Liberci?",
  "src.components.routes.pages.DietParadubice.ReviewBlock.description":
    " Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Pardubice",
  "src.components.routes.pages.DietParadubice.HowWorks.title":
    "funguje naše krabičková dieta v Pardubicích?",
  "src.components.routes.pages.DietParadubice.HowCook.title":
    "NutritionPro - krabičková dieta Pardubice?",
  "src.components.routes.pages.DietSredniCechi.HowCook.title":
    "NutritionPro - krabičková dieta Střední Čechy?",
  "src.components.routes.pages.DietSredniCechi.ReviewBlock.description":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Střední Čechy",
  "src.components.routes.pages.DietSredniCechi.HowWorks.title":
    "funguje naše krabičková dieta ve Středních Čechách?",
  "src.components.routes.pages.DietMlda.HowCook.title":
    "NutritionPro - krabičková dieta Mladá Boleslav?",
  "src.components.routes.pages.DietMlda.ReviewBlock.description":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Mladá Boleslav",
  "src.components.routes.pages.DietMlda.HowWorks.title":
    "funguje naše krabičková dieta v Mladé Boleslavi?",
  "src.components.routes.pages.DietJablonec.HowCook.title":
    " NutritionPro - krabičková dieta Jablonec nad Nisou?",
  "src.components.routes.pages.DietJablonecNisou.ReviewBlock.description":
    " Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Jablonec",
  "src.components.routes.pages.DietJablonecNisou.HowWorks.title":
    "funguje naše krabičková dieta v Jablonci nad Nisou?",
  "src.components.organisms.Faq.menu.subTitle1.plzen":
    "V čem je naše krabičková dieta v Plzni jiná?",
  "src.components.organisms.Faq.menu.subTitle1.liberec":
    "V čem je naše krabičková dieta v Liberci jiná?",
  "src.components.organisms.Faq.menu.subTitle1.hradec":
    "V čem je naše krabičková dieta v Hradci Králové jiná?",
  "src.components.organisms.Faq.menu.subTitle1.sredni":
    "V čem je naše krabičková dieta ve Středních Čechách jiná?",
  "src.components.organisms.Faq.menu.subTitle1.mlda":
    "V čem je naše krabičková dieta v Mladé Boleslavi jiná?",
  "src.components.organisms.Faq.menu.subTitle1.paradubice":
    "V čem je naše krabičková dieta v Pardubicích jiná?",
  "src.components.organisms.Faq.menu.subTitle1.jablonec":
    "V čem je naše krabičková dieta v Jablonci nad Nisou jiná?",
  "src.components.organisms.Faq.menu.content1.plzen":
    "Naše krabičková dieta se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což je něco, co běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.content1.liberec":
    "Naše krabičková dieta v Liberci se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.content1.sredni":
    "Naše krabičková dieta ve Středních Čechách se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.content1.paradubice":
    "Naše krabičková dieta v Pardubicích se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.content1.mlda":
    "Naše krabičková dieta v Mladé Boleslavi se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.content1.hradec":
    "Naše krabičková dieta se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což běžné krabičkové diety často opomíjejí. ",
  "src.components.organisms.Faq.menu.content1.jablonec":
    "Naše krabičková dieta v Jablonci se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše krabičková dieta je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.subTitle2.plzen":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Plzni?",
  "src.components.organisms.Faq.menu.content2.plzen":
    "Jídelníček pro naši krabičkovou dietu je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle2.hradec":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Hradci Králové?",
  "src.components.organisms.Faq.menu.content2.hradec":
    "Jídelníček pro naši krabičkovou dietu je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že se stravou dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle2.liberec":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Liberci?",
  "src.components.organisms.Faq.menu.content2.liberec":
    "Jídelníček pro naši krabičkovou dietu v Liberci je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že se stravou dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle2.paradubice":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Pardubicích?",
  "src.components.organisms.Faq.menu.content2.paradubice":
    "Jídelníček pro naši krabičkovou dietu v Pardubicích je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že se stravou dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle2.sredni":
    "Kdo sestavuje jídelníček pro krabičkovou dietu ve Středních Čechách?",
  "src.components.organisms.Faq.menu.content2.sredni":
    "Jídelníček pro naši krabičkovou dietu ve Středních Čechách je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že se stravou dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle2.mlda":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Mladé Boleslavi?",
  "src.components.organisms.Faq.menu.content2.mlda":
    "Jídelníček pro naši krabičkovou dietu v Mladé Boleslavi je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že se stravou dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle2.jablonec":
    "Kdo sestavuje jídelníček pro krabičkovou dietu v Jablonci nad Nisou?",
  "src.components.organisms.Faq.menu.content2.jablonec":
    "Jídelníček pro naši krabičkovou dietu v Jablonci je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že se stravou dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle3.plzen":
    "Jak vypadá jídelníček v rámci krabičkové diety v Plzni?",
  "src.components.organisms.Faq.menu.content3.plzen":
    "Naše krabičková dieta je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, takže se naše jídla nikdy nepřejí. Podívejte se na ukázku jídelníčku, který nabízíme.",
  "src.components.organisms.Faq.menu.subTitle3.hradec":
    "Jak vypadá jídelníček v rámci krabičkové diety v Hradci Králové?",
  "src.components.organisms.Faq.menu.content3.hradec":
    "Naše krabičková dieta je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, vám se tak naše jídla neomrzela. Podívejte se na ukázku jídelníčku, abyste věděli, jaká jídla můžete čekat.",
  "src.components.organisms.Faq.menu.subTitle3.liberec":
    "Jak vypadá jídelníček v rámci krabičkové diety v Liberci?",
  "src.components.organisms.Faq.menu.content3.liberec":
    "Naše krabičková dieta v Liberci je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, vám se tak naše jídla neomrzela. Podívejte se na ukázku jídelníčku, abyste věděli, jaká jídla můžete čekat.",
  "src.components.organisms.Faq.menu.subTitle3.paradubice":
    "Jak vypadá jídelníček v rámci krabičkové diety v Pardubicích?",
  "src.components.organisms.Faq.menu.content3.paradubice":
    "Naše krabičková dieta v Pardubicích je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, vám se tak naše jídla neomrzela. Podívejte se na ukázku jídelníčku, abyste věděli, jaká jídla můžete čekat.",
  "src.components.organisms.Faq.menu.subTitle3.sredni":
    "Jak vypadá jídelníček v rámci krabičkové diety ve Středních Čechách?",
  "src.components.organisms.Faq.menu.content3.sredni":
    "Naše krabičková dieta ve Středních Čechách je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, vám se tak naše jídla neomrzela. Podívejte se na ukázku jídelníčku, abyste věděli, jaká jídla můžete čekat.",
  "src.components.organisms.Faq.menu.subTitle3.mlda":
    "Jak vypadá jídelníček v rámci krabičkové diety v Mladé Boleslavi?",
  "src.components.organisms.Faq.menu.content3.mlda":
    "Naše krabičková dieta v Mladé Boleslavi je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, vám se tak naše jídla neomrzela. Podívejte se na ukázku jídelníčku, abyste věděli, jaká jídla můžete čekat.",
  "src.components.organisms.Faq.menu.subTitle3.jablonec":
    "Jak vypadá jídelníček v rámci krabičkové diety v Jablonci nad Nisou?",
  "src.components.organisms.Faq.menu.content3.jablonec":
    "Naše krabičková dieta v Jablonci je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, vám se tak naše jídla neomrzela. Podívejte se na ukázku jídelníčku, abyste věděli, jaká jídla můžete čekat.",
  "src.components.organisms.Faq.materials.subTitle3.plzen":
    "Kdy připravujete jídla pro krabičkovou dietu v Plzni?",
  "src.components.organisms.Faq.materials.content3.plzen":
    "Příprava jídel pro naši krabičkovou dietu v Plzni probíhá v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",

  "src.components.organisms.Faq.materials.subTitle3.hradec":
    "Kdy připravujete jídla pro krabičkovou dietu v Hradci Králové?",
  "src.components.organisms.Faq.materials.content3.hradec":
    "Příprava jídel pro naši krabičkovou dietu v Hradci Králové probíhá přímo v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",

  "src.components.organisms.Faq.materials.subTitle3.liberec":
    "Kdy připravujete jídla pro krabičkovou dietu v Liberci?",
  "src.components.organisms.Faq.materials.content3.liberec":
    "Příprava jídel pro naši krabičkovou dietu v Liberci probíhá přímo v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",

  "src.components.organisms.Faq.materials.subTitle3.paradubice":
    "Kdy připravujete jídla pro krabičkovou dietu v Pardubicích?",
  "src.components.organisms.Faq.materials.content3.paradubice":
    "Příprava jídel pro naši krabičkovou dietu v Pardubicích probíhá přímo v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",

  "src.components.organisms.Faq.materials.subTitle3.sredni":
    "Kdy připravujete jídla pro krabičkovou dietu ve Středních Čechách?",
  "src.components.organisms.Faq.materials.content3.sredni":
    "Příprava jídel pro naši krabičkovou dietu ve Středních Čechách probíhá přímo v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",

  "src.components.organisms.Faq.materials.subTitle3.mlda":
    "Kdy připravujete jídla pro krabičkovou dietu v Mladé Boleslavi?",
  "src.components.organisms.Faq.materials.content3.mlda":
    "Příprava jídel pro naši krabičkovou dietu v Mladé Boleslavi probíhá přímo v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",

  "src.components.organisms.Faq.materials.subTitle3.jablonec":
    "Kdy připravujete jídla pro krabičkovou dietu v Jablonci nad Nisou?",
  "src.components.organisms.Faq.materials.content3.jablonec":
    "Příprava jídel pro naši krabičkovou dietu v Jablonci probíhá přímo v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek a ve čtvrtek na pátek a sobotu.",
  "src.components.organisms.Faq.materials.subTitle4.title.plzen":
    "Máte v nabídce i dezerty v rámci krabičkové diety Plzeň?",
  "src.components.organisms.Faq.materials.content4.title.plzen":
    "Ano, naše krabičková dieta zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",

  "src.components.organisms.Faq.materials.subTitle4.title.hradec":
    "Máte v nabídce i dezerty v rámci krabičkové diety Hradec Králové?",
  "src.components.organisms.Faq.materials.content4.title.hradec":
    "Ano, naše krabičková dieta zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",

  "src.components.organisms.Faq.materials.subTitle4.title.liberec":
    "Máte v nabídce i dezerty v rámci krabičkové diety Liberec?",
  "src.components.organisms.Faq.materials.content4.title.liberec":
    "Ano, naše krabičková dieta v Liberci zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",
  "src.components.organisms.Faq.materials.subTitle4.title.paradubice":
    "Máte v nabídce i dezerty v rámci krabičkové diety Pardubice?",
  "src.components.organisms.Faq.materials.content4.title.paradubice":
    "Ano, naše krabičková dieta v Pardubicích zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",

  "src.components.organisms.Faq.materials.subTitle4.title.sredni":
    "Máte v nabídce i dezerty v rámci krabičkové diety Střední Čechy?",
  "src.components.organisms.Faq.materials.content4.title.sredni":
    "Ano, naše krabičková dieta ve Středních Čechách zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",

  "src.components.organisms.Faq.materials.subTitle4.title.mlda":
    "Máte v nabídce i dezerty v rámci krabičkové diety Mladá Boleslav?",
  "src.components.organisms.Faq.materials.content4.title.mlda":
    "Ano, naše krabičková dieta v Mladé Boleslavi zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",

  "src.components.organisms.Faq.materials.subTitle4.title.jablonec":
    "Máte v nabídce i dezerty v rámci krabičkové diety Jablonec nad Nisou?",
  "src.components.organisms.Faq.materials.content4.title.jablonec":
    "Ano, naše krabičková dieta v Jablonci zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",

  "src.components.moelecules.BenefitLabels.text2.hradec":
    "Rozvážíme jídlo ve {head}, nebo na odběrné místo.",
  "src.components.moelecules.BenefitLabels.subText2.hradec":
    "2 hodinových oknech",

  "src.components.moelecules.BenefitLabels.text2.liberec":
    "Rozvážíme jídlo ve {head}, nebo na odběrné místo.",
  "src.components.moelecules.BenefitLabels.subText2.liberec":
    "2 hodinových oknech",

  "src.components.moelecules.BenefitLabels.text2.paradubice":
    "Rozvážíme jídlo ve {head}.",
  "src.components.moelecules.BenefitLabels.subText2.paradubice":
    "2 hodinových oknech",

  "src.components.moelecules.BenefitLabels.text2.sredni":
    "Rozvážíme jídlo ve {head}.",
  "src.components.moelecules.BenefitLabels.subText2.sredni":
    "večerních hodinách",

  "src.components.moelecules.BenefitLabels.text2.mlda":
    "Rozvážíme jídlo ve {head}, nebo na odběrné místo.",
  "src.components.moelecules.BenefitLabels.subText2.mlda":
    "2 hodinových oknech",

  "src.components.moelecules.BenefitLabels.text2.jablonec":
    "Rozvážíme jídlo ve {head}.",
  "src.components.moelecules.BenefitLabels.subText2.jablonec":
    "večerních hodinách",

  "src.components.moelecules.BenefitLabels.text2.brno":
    "Rozvážíme jídlo ve {head}, nebo na odběrná místa.",
  "src.components.moelecules.BenefitLabels.subText2.brno":
    "2 hodinových oknech",

  "src.components.moelecules.BenefitLabels.text2.plzen":
    "Rozvážíme jídlo ve {head}, nebo na odběrná místa.",
  "src.components.moelecules.BenefitLabels.subText2.plzen":
    "2 hodinových oknech",

  "src.components.organisms.Faq.delivery.content8.text2.plzen":
    "V Plzni nabízíme 3 možnosti časových oken:",
  "src.components.organisms.Faq.delivery.content8.text3.plzen":
    "17:00 – 20:00 | 18:00 – 21:00 | 19:00 – 21:00",
  "src.components.organisms.Faq.delivery.content8.text4.plzen":
    "Vybereš si, které ti sedí nejvíce, a na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content9.plzen":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 10:00.",
  "src.components.organisms.Faq.delivery.content9.liberec":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 10:00.",
  "src.components.organisms.Faq.delivery.content8.text1.liberec":
    "V Liberci ti jídlo doručíme v časovém rozpětí 17:00 – 22:00, na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content8.text1.hradec":
    "V Hradci Králové ti jídlo doručíme v časovém rozpětí 16:30 – 22:00, na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content9.hradec":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 10:00.",
  "src.components.organisms.Faq.delivery.content8.text1.paradubice":
    "V Pardubicích ti jídlo doručíme v časovém rozpětí 16:30 – 22:00, na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content9.paradubice":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 10:00.",
  "src.components.organisms.Faq.delivery.content8.text1.sredni":
    "V okolí Prahy ti jídlo doručíme v časovém rozpětí 17:00 – 22:00, na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content9.sredni":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 11:00.",
  "src.components.organisms.Faq.delivery.content8.text1.jablonec":
    "V Jablonci nad Nisou ti jídlo doručíme v časovém rozpětí 18:00 – 22:00, na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content9.jablonec":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 10:00.",
  "src.components.organisms.Faq.delivery.content8.text1.mlda":
    "V Mladé Boleslavi ti jídlo doručíme v časovém rozpětí 16:00 – 22:00, na konkrétním čase se domluvíš přímo s kurýrem.",
  "src.components.organisms.Faq.delivery.content9.mlda":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 10:00.",
  "src.components.organisms.CityDeliveryBlock.list.description3.sredni":
    "Změnily se ti plány? Čas a místo si můžeš flexibilně změnit ještě v den dovozu do 11:00",
  "src.components.routes.pages.PravilaCookies.title": "PravilaCookies",
  "src.components.organisms.HomePageBanner.title1": "Finally",
  "src.components.organisms.HomePageBanner.title2": "box diet,",
  "src.components.organisms.HomePageBanner.title3": "which is really worth it!",
  "src.components.molecules.DemoDiscountInfo.promocode": "DEMO11",
  "src.components.molecules.DemoDiscountInfo.percent": "SLEVA 50%",
  "src.components.molecules.DemoDiscountInfo.text": "S PROMOKODEM",
  "src.components.organisms.ComplateMenu.discountText":
    "Super cena s promokódem DEMO11",
  "src.components.molecules.OnlineOrderThiredStepSidebar.previousPrice":
    "Původní cena",
  "src.components.organisms.FaqReferal.title": "Odpovědi na nejčastější otázky",
  "src.components.organisms.FaqReferal.title1":
    "Kde najdu svůj unikátní osobní odkaz?",
  "src.components.organisms.FaqReferal.title2":
    "Kdy a jak můžu svou slevu využít?",
  "src.components.organisms.FaqReferal.title4":
    "Mohu slevu kombinovat s jinými akcemi nebo kódy?",
  "src.components.organisms.FaqReferal.title5": "Kolik přátel mohu doporučit?",
  "src.components.organisms.FaqReferal.title6":
    "Co když můj známý objednávku nezaplatí, ale zruší?",
  "src.components.organisms.FaqReferal.title7": "Na jaké programy sleva platí?",
  "src.components.organisms.FaqReferal.title8":
    "Co když můj kamarád už je vaším klientem?",
  "src.components.organisms.FaqReferal.title9":
    "Doporučil jsem vás, ale zapomněl jsem použít svůj unikátní odkaz. Co teď?",
  "src.components.organisms.FaqReferal.content1.bold": "Doporučení",
  "src.components.organisms.FaqReferal.content1.info": "zákaznickém portálu",
  "src.components.organisms.FaqReferal.content1":
    "Po přihlášení k osobnímu účtu v {liniInfo} hledej sekci {boldInfo}. Tam najdeš odkaz, který stačí zkopírovat a sdílet s přáteli.",
  "src.components.organisms.FaqReferal.content2":
    "Sleva se uplatní automaticky při prodloužení tvého programu NutritionPro. Jedinou podmínkou je, aby objednávka z tvého doporučení byla zaplacená.",
  "src.components.organisms.FaqReferal.content4":
    "Bohužel, slevu za doporučení nelze kombinovat s jinými slevami nebo promo kódy.",
  "src.components.organisms.FaqReferal.content5":
    "Kolik jenom zvládneš, žádné omezení není. Slevu získáš pokaždé, když nový klient objedná a objednávku uhradí.",
  "src.components.organisms.FaqReferal.content6":
    "Potom se žádná sleva nikomu neaktivuje.",
  "src.components.organisms.FaqReferal.content7":
    "Na všechny! Slevu můžeš uplatnit na jakýkoli alespoň 10denní program v nabídce NutritionPro bez ohledu na typ.",
  "src.components.organisms.FaqReferal.content8":
    "Sleva za doporučení se neuplatňuje pro klienty s aktivním programem. Je určena pouze pro nové nebo vracející se zákazníky.",
  "src.components.organisms.FaqReferal.content9":
    "Ačkoli bychom moc chtěli pomoct, technicky to není možné. Vždy se proto ujisti, že známý objednává rozkliknutím tvého unikátního odkazu.",
  "src.components.routes.pages.Referal.title": "Referal",

  "src.components.organisms.HowWorksReferal.title":
    "Co musím pro tu dvojitou tisícovku udělat?",
  "src.components.organisms.HowWorksReferal.step1":
    "V sekci {info} najdeš svůj osobní unikátní odkaz. Prosíme, nezapomeň vyplnit krátký formulář, abychom mohli tvoje doporučení sledovat a dokázali jsme nové objednávky párovat ke tvému účtu.",
  "src.components.organisms.HowWorksReferal.step1.info": "Doporučení",
  "src.components.organisms.HowWorksReferal.step2":
    "Rozkliknutím tvého osobního odkazu získají okamžitou slevu 1000 Kč na jakýkoliv online program delší než 7 dní. Zlobit se určitě nebudou.",
  "src.components.organisms.HowWorksReferal.step3":
    "Jakmile bude doporučená objednávka zaplacena, druhá tisícovka se připíše na tvůj NutritionPro účet (proto musíš být klientem). Automaticky ti potom odečteme 1000 Kč při prodloužení tvého programu. Prostě win-win-win!",
  "src.components.organisms.HowWorksReferal.step4":
    "Můžeš pozvat tolik známých, kolik budeš chtít. Za každé doporučení dostanete slevu 1000 Kč. Oba dva. Automaticky. Bez vytáček.",
  "src.components.organisms.HowWorksReferal.title1":
    "Přihlas se do zákaznického portálu",
  "src.components.organisms.HowWorksReferal.title2":
    "Sdílej odkaz s rodinou a přáteli",
  "src.components.organisms.HowWorksReferal.title3":
    "Užij si slevu při prodloužení programu",
  "src.components.organisms.HowWorksReferal.title4": "Doporučuj dál!",
  "src.components.organisms.forms.referalTitle": "Ozvěte se mi",
  "src.components.organisms.HowWorksReferal.form.title":
    "Máš dotazy nebo nápady?",
  "src.components.organisms.HowWorksReferal.form.description":
    "Pojďme je probrat.",
  "src.components.organisms.ReferalBanner.redPrice":
    "Sdílej radost z krabiček a ušetříš",
  "src.components.organisms.ReferalBanner.btn": "Doporuč nás >",
  "src.components.organisms.ReferalBanner.title1": "Doporuč NutritionPro ",
  "src.components.organisms.ReferalBanner.title2": "známým a oba",
  "src.components.organisms.ReferalBanner.title3": " dostanete 1000 Kč!",
  "src.components.organisms.HowWorksReferal.prihlasit": "Přihlásit se",
  "src.components.organisms.OnlineOrderSecondStep.error.email":
    "Fill the email",
  "src.components.organisms.DesktopHeader.referal": "Recommend us",
  "src.components.organisms.Footer.akceData": "Pravidla akce „Doporučení“",
  "src.components.routes.pages.GiftVoucher.title": "Gift voucher",
  "error.voucherType.required": "requried",
  "error.price.required": "requried",

  "src.component.molecules.CityLoaction.text": "Lokalita:",
  "src.components.molecules.CityLocation.praha": "Praha",
  "src.components.molecules.CityLocation.brno": "Brno",
  "src.components.molecules.CityLocation.plzen": "Plzeň",
  "src.components.molecules.CityLocation.hradec": "Hradec Králové",
  "src.components.molecules.CityLocation.mlda": "Mladá Boleslav",
  "src.components.molecules.CityLocation.paradubice": "Pardubice",
  "src.components.molecules.CityLocation.jablonec": "Jablonec",
  "src.components.molecules.CityLocation.sredni": "Střední Čechy",
  "src.components.molecules.CityLocation.liberec": "Liberec",
  "src.components.molecules.OnlineMenuSlider.mobile.meat.title": "Classic",
  "src.components.molecules.OnlineMenuSlider.mobile.vege.title": "Vege",
  "src.components.molecules.OnlineMenuSlider.mobile.fish.title": "Bez ryb",
  "src.components.organisms.OrderOnlineFirstStep.peopledescription":
    "Sleva platí pouze na první objednávku",
  "src.components.organisms.OrderOnlineFirstStep.aditionalProduct.title":
    "Přidej pitný režim",
  "src.components.organisms.OrderOnlineFirstStep.aditionalProduct.description":
    "Mattoni baličky, vice info",
  "src.components.organisms.OrderOnlineFirstStep.aditionalProduct.descriptionDesk":
    "Pitný režim je nezbytnou součástí vaší stravy, a proto jsme navázali spolupráci s Mattoni, abychom vám mohli nabídnout výběr ze tří variant.",
  "src.components.organisms.DesktopHeader.voucher": "Dárkový voucher",
  "src.components.routes.pages.DietZlin.howCook":
    "NutritionPro - krabičková dieta Zlín ?",
  "src.components.routes.pages.DietLabu.howCook":
    "NutritionPro - krabičková dieta Ústí nad Labem ?",
  "src.components.routes.pages.DietTeplice.howCook":
    "NutritionPro - krabičková dieta Teplice ?",
  "src.components.routes.pages.DietZlin.review":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Zlín",
  "src.components.routes.pages.DietLabu.review":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Ústí nad Labem",
  "src.components.routes.pages.DietTeplice.review":
    "Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Teplice",

  "src.components.organisms.HowWorks.daily.zlin":
    "Nastavíme tvůj denní příjem podle tvých cílů",
  "src.components.organisms.HowWorks.algorithm.zlin":
    "Speciální algoritmus sestaví jídelníček na míru",
  "src.components.organisms.HowWorks.cooking.zlin": "Jídlo uvaříme",
  "src.components.organisms.HowWorks.delivery.zlin":
    "Dovezeme ti domů či do práce",
  "src.components.organisms.HowWorks.goal.zlin":
    " Následně dohlédneme na to, abys svého cíle dosáhl | Krabičková dieta Zlín",
  "src.components.organisms.HowWorks.titleFirst.zlin": "Jak funguje naše ",
  "src.components.organisms.HowWorks.titleSecond.zlin":
    "krabičková dieta ve Zlíně?",

  "src.components.organisms.HowWorks.daily.labu":
    "Nastavíme tvůj denní příjem podle tvých cílů",
  "src.components.organisms.HowWorks.algorithm.labu":
    "Speciální algoritmus sestaví jídelníček na míru",
  "src.components.organisms.HowWorks.cooking.labu": "Jídlo uvaříme",
  "src.components.organisms.HowWorks.delivery.labu":
    "Dovezeme ti domů či do práce",
  "src.components.organisms.HowWorks.goal.labu":
    " Následně dohlédneme na to, abys svého cíle dosáhl | Krabičková dieta Ústí nad Labem",
  "src.components.organisms.HowWorks.titleFirst.labu": "Jak funguje naše ",
  "src.components.organisms.HowWorks.titleSecond.labu":
    "krabičková dieta v Ústí nad Labem?",

  "src.components.organisms.HowWorks.daily.teplice":
    "Nastavíme tvůj denní příjem podle tvých cílů",
  "src.components.organisms.HowWorks.algorithm.teplice":
    "Speciální algoritmus sestaví jídelníček na míru",
  "src.components.organisms.HowWorks.cooking.teplice": "Jídlo uvaříme",
  "src.components.organisms.HowWorks.delivery.teplice":
    "Dovezeme ti domů či do práce",
  "src.components.organisms.HowWorks.goal.teplice":
    " Následně dohlédneme na to, abys svého cíle dosáhl | Krabičková dieta Teplice",
  "src.components.organisms.HowWorks.titleFirst.teplice": "Jak funguje naše ",
  "src.components.organisms.HowWorks.titleSecond.teplice":
    "krabičková dieta v Teplicích ?",
    "src.components.organisms.FaqPraha.menu.content1.bold.labu":
    "krabičková dieta Ústí nad Labem",
    "src.components.organisms.FaqPraha.menu.content1.bold.telpice":
    "krabičková dieta Teplice",
    "src.components.organisms.FaqPraha.menu.content1.bold.zlin":
    "krabičková dieta Zlín",
    "src.components.organisms.FaqPraha.menu.content2.bold.zlin":
    "krabičkovou dietu Zlín",
    "src.components.organisms.FaqPraha.menu.content2.bold.labu":
    "krabičkovou dietu Ústí nad Labem",
    "src.components.organisms.FaqPraha.menu.content2.bold.teplice":
    "krabičkovou dietu Teplice",


    "src.components.organisms.FaqPraha.menu.content3.boldInfo.zlin":
    "krabičkovou dietu Zlín",
    "src.components.organisms.FaqPraha.menu.content3.boldInfo.labu":
    "krabičkovou dietu Ústí nad Labem",
    "src.components.organisms.FaqPraha.menu.content3.boldInfo.teplice":
    "krabičkovou dietu Teplice",
    "src.components.organisms.FaqPraha.materials.content1.bold.zlin":
    "krabičkovou dietu Zlín",
    "src.components.organisms.FaqPraha.materials.content1.bold.labu":
    "krabičkovou dietu Ústí nad Labem",
    "src.components.organisms.FaqPraha.materials.content1.bold.teplice":
    "krabičkovou dietu Teplice",

    "src.components.organisms.FaqPraha.materials.content2.bold.zlin":
    "krabičkové diety Zlín",
    "src.components.organisms.FaqPraha.materials.content2.bold.labu":
    "krabičkové diety Ústí nad Labem",
    "src.components.organisms.FaqPraha.materials.content2.bold.teplice":
    "krabičkové diety Teplice",

    "src.components.organisms.FaqPraha.materials.content4.bold.zlin":
    "krabičkovou dietu Zlín",
    "src.components.organisms.FaqPraha.materials.content4.bold.labu":
    "krabičkovou dietu Ústí nad Labem",
    "src.components.organisms.FaqPraha.materials.content4.bold.teplice":
    "krabičkovou dietu Teplice",
    "src.components.organisms.FaqPraha.delivery.content1.bold.zlin":
    "krabičkovou dietu Zlín",
    "src.components.organisms.FaqPraha.delivery.content1.bold.labu":
    "krabičkovou dietu Ústí nad Labem",
    "src.components.organisms.FaqPraha.delivery.content1.bold.teplice":
    "krabičkovou dietu Teplice",

    "src.components.organisms.FaqPraha.delivery.content2.bold.zlin":
    "krabičkovou diety Zlín",
    "src.components.organisms.FaqPraha.delivery.content2.bold.labu":
    "krabičkovou diety Ústí nad Labem",
    "src.components.organisms.FaqPraha.delivery.content2.bold.teplice":
    "krabičkovou diety Teplice",


    "src.components.organisms.FaqPraha.delivery.content3.bold.zlin":
    "krabičkové diety Zlín",
    "src.components.organisms.FaqPraha.delivery.content3.bold.labu":
    "krabičkové diety Ústí nad Labem",
    "src.components.organisms.FaqPraha.delivery.content3.bold.teplice":
    "krabičkové diety Teplice",


    "src.components.organisms.FaqPraha.delivery.content4.bold.zlin":
    "krabičkové diety Zlín",
    "src.components.organisms.FaqPraha.delivery.content4.bold.labu":
    "krabičkové diety Ústí nad Labem",
    "src.components.organisms.FaqPraha.delivery.content4.bold.teplice":
    "krabičkové diety Teplice",


    "src.components.organisms.FaqPraha.delivery.content7.bold.zlin":
    "krabičkové diety Zlín",
    "src.components.organisms.FaqPraha.delivery.content7.bold.labu":
    "krabičkové diety Ústí nad Labem",
    "src.components.organisms.FaqPraha.delivery.content7.bold.teplice":
    "krabičkové diety Teplice",

    "src.components.organisms.FaqPraha.delivery.content9.bold.zlin":
    "krabičkové diety Zlín",
    "src.components.organisms.FaqPraha.delivery.content9.bold.labu":
    "krabičkové diety Ústí nad Labem",
    "src.components.organisms.FaqPraha.delivery.content9.bold.teplice":
    "krabičkové diety Teplice",


    "src.components.organisms.FaqPraha.consumption.content1.bold.zlin":
    "krabičkové diety Zlín",
    "src.components.organisms.FaqPraha.consumption.content1.bold.labu":
    "krabičkové diety Ústí nad Labem",
   "src.components.organisms.FaqPraha.consumption.content1.bold.teplice":
    "krabičkové diety Teplice",
    "src.components.organisms.FaqPraha.consumption.content2.bold.zlin":
    "krabičkové diety Zlín",
    "src.components.organisms.FaqPraha.consumption.content2.bold.labu":
    "krabičkové diety Ústí nad Labem",
   "src.components.organisms.FaqPraha.consumption.content2.bold.teplice":
    "krabičkové diety Teplice",
    "src.components.organisms.FaqPraha.consumption.content3.bold.zlin":
    "krabičkové diety Zlín",
    "src.components.organisms.FaqPraha.consumption.content3.bold.labu":
    "krabičkové diety Ústí nad Labem",
   "src.components.organisms.FaqPraha.consumption.content3.bold.teplice":
    "krabičkové diety Teplice",

    "src.components.organisms.FaqPraha.client.content1.bold.zlin":
    "krabičková dieta Zlín",
    "src.components.organisms.FaqPraha.client.content1.bold.labu":
    "krabičková dieta Ústí nad Labem",
   "src.components.organisms.FaqPraha.client.content1.bold.teplice":
    "krabičková dieta Teplice",

    "src.components.organisms.FaqPraha.client.content2.bold.zlin":
    "krabičkové diety Zlín",
    "src.components.organisms.FaqPraha.client.content2.bold.labu":
    "krabičkové diety Ústí nad Labem",
   "src.components.organisms.FaqPraha.client.content2.bold.teplice":
    "krabičkové diety Teplice",
    "src.components.organisms.HomePageBanner.title4":"KTERÁ SE VYPLATÍ!",
  "src.components.organisms.HomeNewYearBanner.btn":"Spočítat cenu a získat dopravu zdarma!",


  

};
